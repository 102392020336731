/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useRef } from "react";

// Dropzone components
import Dropzone from "dropzone";

// Dropzone styles
import "dropzone/dist/dropzone.css";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";

// Custom styles for the MDDropzone
import MDDropzoneRoot from "components/MDDropzone/MDDropzoneRoot";

// Material Dashboard 2 PRO React context
import { useMaterialUIController } from "context";

// Declaring props types for MDDropzone
interface Props {
  options: {
    [key: string | number]: any;
  };
  uploadFiles: (file: any) => void;
  removeFile: (file: any) => void;
}

function MDDropzone({ options, uploadFiles, removeFile }: Props): JSX.Element {
  const [controller] = useMaterialUIController();
  const { darkMode } = controller;

  const dropzoneRef = useRef<HTMLFormElement | null>(null);

  useEffect(() => {
    Dropzone.autoDiscover = false;

    function createDropzone() {
      return new Dropzone(dropzoneRef.current, { ...options });
    }

    function removeDropzone() {
      if (Dropzone.instances.length > 0) Dropzone.instances.forEach((dz: any) => dz.destroy());
    }

    createDropzone()
      .on("complete", function (file: any, response: any) {
        console.log('complete:', file);
        uploadFiles(file);
      })
      .on("removedfile", function (file: any, response: any) {
        console.log('removed:', file);
        removeFile(file);
      })
      .on("maxfilesexceeded", function (file: any, response: any) {
        console.log('maxFilesExceeded:', file);
      })

    return () => removeDropzone();
  }, [options]);


  return (
    <MDDropzoneRoot
      action="https://yogurtrain.cartapi.taotaro.app/rapidcart/admin/oss/uploadImage"
      ref={dropzoneRef}
      className="form-control dropzone"
      ownerState={{ darkMode }}
    >
      <MDBox className="fallback" bgColor="transparent">
        <input name="file" type="file" multiple />
      </MDBox>
    </MDDropzoneRoot>
  );
}

MDDropzone.defaultProps = {
  uploadFiles: () => {},
  removeFile: () => {}
};

export default MDDropzone;
