/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useContext, useState } from "react";
import { Link } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

//Context
import { AuthContext } from "context/auth";

//API
import Axios from "axios";
import { url } from "../../../utils/constant";

function Cover(): JSX.Element {

  const { signIn } = useContext(AuthContext);

  const [values, setValues] = useState({
    account: '',
    password: '',
  });

  const [credentials, setCredentials] = useState({
    newPassword: '',
    captcha: '',
  })

  const [errorSB, setErrorSB] = useState<boolean>(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const handleCaptcha = () => {
    try {
      const result = Axios.post(`${url}/rapidcart/admin/sendMailCode`);

      console.log('Get Captcha Result:', result);
    } catch (err) {
      console.log("Get Captcha Error:", err);
    }
  };

  const handlePasswordChange = async (credentials: any) => {
    try {
      const result = await Axios.post(`${url}/rapidcart/admin/changePassword`, credentials);

      console.log('Change Password Result:', result);

    } catch (err) {
      console.log("Change Password Error:", err);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleCredentialChange = (e: any) => {
    const { name, value } = e.target;
    setCredentials((prevState) => ({ ...prevState, [name]: value }));
  };

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Missing Fields"
      content="You must complete all mandatory fields!"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const handleSubmit = async (e: any) => {
    e.preventDefault();

    if (values.account === "" 
        || values.password === "" 
        || credentials.newPassword === ""
        || credentials.captcha === ""
        )
    {
      openErrorSB();
      return
    }

    await signIn(values);
    await handlePasswordChange(credentials);
  };

  console.log('values:', values);

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Sign In & Password Update
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            You may sign in and update your password now.
          </MDTypography>
        </MDBox>

        <MDBox pt={4} pb={3} px={3}>
          <MDBox component="form" role="form" onSubmit={handleSubmit}>
            <MDBox mb={2}>
              <MDInput 
                type="text" 
                name="account"
                onChange={handleChange}
                label="Email Address" 
                variant="standard" 
                fullWidth 
              />
            </MDBox>
            {/* <MDBox mb={2}>
              <MDInput type="email" label="Email" variant="standard" fullWidth />
            </MDBox> */}
            <MDBox mb={2}>
              <MDInput 
                type="text" 
                name="password"
                onChange={handleChange}
                label="Received Password" 
                variant="standard" 
                fullWidth 
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput 
                type="text" 
                name="newPassword"
                onChange={handleCredentialChange}
                label="New Password" 
                variant="standard" 
                fullWidth 
              />
            </MDBox>
            <MDBox mb={2}>
              <MDInput 
                type="text" 
                name="captcha"
                onChange={handleCredentialChange}
                label="Captcha Code" 
                variant="standard" 
                fullWidth 
              />
            </MDBox>
            {/* <MDBox display="flex" alignItems="center" ml={-1}>
              <Checkbox />
              <MDTypography
                variant="button"
                fontWeight="regular"
                color="text"
                sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
              >
                &nbsp;&nbsp;I agree the&nbsp;
              </MDTypography>
              <MDTypography
                component="a"
                href="#"
                variant="button"
                fontWeight="bold"
                color="info"
                textGradient
              >
                Terms and Conditions
              </MDTypography>
            </MDBox> */}
            <MDBox mt={4} mb={1}>
              <MDButton 
                variant="gradient" 
                color="warning" 
                onClick={handleCaptcha} 
                fullWidth
              >
                Get Captcha Code
              </MDButton>
            </MDBox>
            <MDBox mt={2} mb={1}>
              <MDButton variant="gradient" color="info" type="submit" fullWidth>
                Submit & Sign In
              </MDButton>
            </MDBox>
          </MDBox>
        </MDBox>
        
        <MDBox mt={3} mb={1} textAlign="center">
          <MDTypography variant="button" color="text">
            Already have an account?{" "}
            <MDTypography
              component={Link}
              to="/sign-in"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign In
            </MDTypography>
          </MDTypography>
        </MDBox>

        {renderErrorSB}
      </Card>
    </CoverLayout>
  );
}

export default Cover;
