import React, { useContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { AuthContext } from "context/auth";

const PrivateRoutes = () => {

  const { auth } = useContext(AuthContext);
  
  return (
    auth ? <Outlet /> : <Navigate to="/sign-in" />
  )
}

export default PrivateRoutes;