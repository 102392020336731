// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

interface Props {
  values: { [key: string]: any };
  percentDiscount: { [key: string]: any };
  inputUpdates: (event: any) => void;
  discountUpdates?: (event: any) => void;
  discountPercentUpdates?: (event: any) => void;
  addMixRules: () => void;
  removeMixRules: (event: any) => void;
}

function ProductInfo({
  values,
  percentDiscount,
  inputUpdates,
  discountUpdates,
  discountPercentUpdates,
  addMixRules,
  removeMixRules,
}: Props): JSX.Element {
  const {
    title,
    description,
    description2,
    description3,
    promotionDescription,
    salesPolicy,
    discountRules,
  } = values;

  let groupPromo: any = [];

  if (discountRules.length > 0) {
    for (let rule = 0; rule < discountRules.length; rule++) {
      if (discountRules[rule].type === "OVER_AMOUNT_DISCOUNT") {
        groupPromo.push(discountRules[rule]);
      }
    }
  }

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Product Information</MDTypography>
        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                name="title"
                value={title}
                type="text"
                label="Title"
                onChange={inputUpdates}
              />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
            <FormField type="text" label="Weight" />
          </Grid> */}
          </Grid>
        </MDBox>
        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="flex">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Description 1
                </MDTypography>
              </MDBox>
              <MDInput
                name="description"
                value={description}
                onChange={inputUpdates}
                multiline
                rows={5}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="flex">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Description 2&nbsp;&nbsp;
                  <MDTypography variant="caption" color="text">
                    (optional)
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDInput
                name="description2"
                value={description2}
                onChange={inputUpdates}
                multiline
                rows={5}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="flex">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Description 3&nbsp;&nbsp;
                  <MDTypography variant="caption" color="text">
                    (optional)
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDInput
                name="description3"
                value={description3}
                onChange={inputUpdates}
                multiline
                rows={5}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="flex">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Promotion Description&nbsp;&nbsp;
                  <MDTypography variant="caption" color="text">
                    (optional)
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDInput
                name="promotionDescription"
                value={promotionDescription}
                onChange={inputUpdates}
                multiline
                rows={5}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="flex">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Sales Policy&nbsp;&nbsp;
                  <MDTypography variant="caption" color="text">
                    (optional)
                  </MDTypography>
                </MDTypography>
              </MDBox>
              <MDInput
                name="salesPolicy"
                value={salesPolicy}
                onChange={inputUpdates}
                multiline
                rows={5}
                fullWidth
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="flex">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Product Listing Set Discount Rule&nbsp;&nbsp;
                  <MDTypography variant="caption" color="text">
                    (Only for Mix & Match)
                  </MDTypography>
                </MDTypography>
              </MDBox>

              <MDBox mb={1} ml={0.5} lineHeight={0} display="flex">
                <MDTypography variant="caption" color="text">
                  The product itself has a discount rule. When filled, adding multiple variants of
                  this product listing will return the following set discount.
                </MDTypography>
              </MDBox>

              {groupPromo.map((item: any, index: any) => (
                <>
                  <Grid container spacing={3} key={index}>
                    <Grid item xs={12} sm={5}>
                      <FormField
                        id={`MIX_AND_MATCH_DISCOUNT-${index}`}
                        a-key={index}
                        type="text"
                        label="Set Mix & Match Quantity"
                        placeholder="0.00"
                        name="unitQuantity"
                        value={item.unitQuantity}
                        onChange={discountUpdates}
                      />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                      <FormField
                        id={`MIX_AND_MATCH_DISCOUNT-${index}`}
                        a-key={index}
                        type="text"
                        label="Set  Mix & Match Price"
                        placeholder="0.00"
                        name="unitDiscount"
                        value={item.unitDiscount}
                        onChange={discountUpdates}
                      />
                    </Grid>

                    {groupPromo.length > 1 && (
                      <MDButton
                        id={index}
                        variant="gradient"
                        color="dark"
                        size="small"
                        style={{ marginLeft: "10px", marginTop: "30px", height: "30px" }}
                        onClick={removeMixRules}
                      >
                        Remove
                      </MDButton>
                    )}
                  </Grid>

                  {groupPromo.length - 1 === index && groupPromo.length < 4 && (
                    <MDBox
                      display="flex"
                      justifyContent="space-between"
                      alignItems="flex-start"
                      flexWrap="wrap"
                      style={{ marginTop: "10px" }}
                    >
                      <MDButton variant="gradient" color="dark" size="small" onClick={addMixRules}>
                        Add More Mix & Match Discounts
                      </MDButton>
                    </MDBox>
                  )}
                </>
              ))}
            </Grid>

            <Grid item xs={12} sm={12}>
              <MDBox mb={1} ml={0.5} lineHeight={0} display="flex">
                <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                  Product Listing Percentage Discount by Amount Threshold &nbsp;&nbsp;
                  <MDTypography variant="caption" color="text">
                    (Only for Mix & Match - Will apply to all flavours)
                  </MDTypography>
                </MDTypography>
              </MDBox>

              <MDBox mb={1} ml={0.5} lineHeight={0} display="flex">
                <MDTypography variant="caption" color="text">
                  The product itself has a discount rule. When filled, adding multiple variants of
                  this product listing with a threshold of the set amount will provide a percentage
                  discount.
                </MDTypography>
              </MDBox>

              <Grid container spacing={3}>
                <Grid item xs={12} sm={5}>
                  <FormField
                    type="text"
                    label="Set Mix & Match Amount Threshold"
                    placeholder="0.00"
                    name="unitQuantity"
                    value={percentDiscount.unitQuantity}
                    onChange={discountPercentUpdates}
                  />
                </Grid>

                <Grid item xs={12} sm={5}>
                  <FormField
                    type="text"
                    label="Set Mix & Match Discount Percentage (eg: 10% off, enter 90)"
                    placeholder="0.00"
                    name="unitDiscount"
                    value={percentDiscount.unitDiscount}
                    onChange={discountPercentUpdates}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

ProductInfo.defaultProps = {
  values: {
    title: "",
    description: "",
    description2: "",
    description3: "",
  },
};

export default ProductInfo;
