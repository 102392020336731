import { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { message } from 'antd';

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// EditProduct page components
import ProductImage from "./components/ProductImage";
import ProductInfo from "./components/ProductInfo";
// import Pricing from "./components/Pricing";
import ProductPosition from "./components/ProductPosition";
import DynamicPriceCard from "./components/DynamicPriceCard";

//API
import Axios from "axios";
import { url } from "../../../utils/constant";

function UpdateProduct(): JSX.Element {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    id: 0,
    title: "",
    description: "",
    description2: "",
    description3: "",
    discountRules: [],
    promotionDescription: "",
    salesPolicy: "",
    price: "",
    quantity: 0,
    remarks: "",
    pictureUrls: [],
    skuList: [],
  });

  const [percentDiscount, setPercentDiscount] = useState({
    unitDiscount: 0,
    unitQuantity: 0,
  });

  const [productPosition, setProductPosition] = useState("");

  //image source clone to display
  const [imageFiles, setImageFiles] = useState([]);
  const [sendFiles, setSendFiles] = useState([]);
  const [successSB, setSuccessSB] = useState<boolean>(false);

  let id = useParams();

  const queryOneInventory = async () => {
    try {
      // const result = await Axios.post(`${url}/rapidcart/admin/inventory/queryAll`, id);
      const result = await Axios.post(`${url}/rapidcart/admin/inventory/detail`, id);

      let data = result.data.data;

      // console.log("Get One Res:", data);

      setValues((prevState) => ({
        ...prevState,
        id: data.id,
        title: data.title,
        price: data.price,
        description: data.description,
        description2: data.description2,
        description3: data.description3,
        discountRules: data.discountRules || [],
        promotionDescription: data.promotionDescription || "",
        salesPolicy: data.salesPolicy || "",
        remarks: data.remarks,
        pictureUrls: data.pictureUrls,
        skuList: data.skuList,
      }));

      if (data.discountRules === null || data.discountRules.length === 0) {
        let productDiscount = [];

        productDiscount.push({
          type: "OVER_AMOUNT_DISCOUNT",
          unitQuantity: 0,
          unitDiscount: 0,
        });

        data.discountRules = productDiscount;

        setValues(data);
      }

      const percentDiscountObject = data.discountRules.find(
        (item: any) => item.type === "OVER_AMOUNT_PERCENT_DISCOUNT"
      );
      setPercentDiscount((prevState) => ({
        ...prevState,
        type: "OVER_AMOUNT_PERCENT_DISCOUNT",
        unitDiscount: percentDiscountObject ? percentDiscountObject.unitDiscount : 0,
        unitQuantity: percentDiscountObject ? percentDiscountObject.unitQuantity : 0,
      }));

      setImageFiles(data.pictureUrls);

      let skuList = result.data.data.skuList;

      skuList.forEach((obj: any, sIndex: any) => {
        // let discountRules = obj.discountRules || [];
        let discountRules = obj.discountRules;

        if (discountRules.length > 0) {
          let outcome;

          for (let i = 0; i < discountRules.length; i++) {
            let rule = discountRules[i];

            if (rule.type === "OVER_AMOUNT_DISCOUNT") {
              let overArr = [...data.skuList[sIndex].discountRules];

              overArr.push({
                type: "MIN_UNIT_DISCOUNT",
                unitQuantity: 0,
                unitDiscount: 0,
              });

              overArr.unshift(overArr.pop());

              outcome = overArr;
            }
          }

          data.skuList[sIndex].discountRules = outcome;
        } else {
          data.skuList[sIndex].discountRules.push(
            {
              type: "MIN_UNIT_DISCOUNT",
              unitQuantity: 0,
              unitDiscount: 0,
            },
            {
              type: "OVER_AMOUNT_DISCOUNT",
              unitQuantity: 0,
              unitDiscount: 0,
            }
          );

          setValues(data);
        }
      });
    } catch (err) {
      console.log("Get One Error:", err);
    }
  };

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  //To reference input tag when click Edit button
  const inputRef = useRef<HTMLInputElement>(null);

  const triggerFileSelect = () => {
    if (null !== inputRef.current) {
      inputRef.current.click();
    }
  };

  //Add Card
  const handleAddCard = () => {
    setValues((prev) => ({
      ...prev,
      skuList: [
        ...prev.skuList,
        {
          salePrice: 0,
          stock: 0,
          discountRules: [
            {
              type: "MIN_UNIT_DISCOUNT",
              unitQuantity: 0,
              unitDiscount: 0,
            },
            {
              type: "OVER_AMOUNT_DISCOUNT",
              unitQuantity: 0,
              unitDiscount: 0,
            },
          ],
        },
      ],
    }));
  };
  //Remove Card
  const handleRemoveCard = (index: any) => {
    console.log("Index:", index);
    // const list = [...dynamicPromo];
    //list.splice(index, 1);
    // setDynamicPromo(list);

    const cloneData = { ...values };
    cloneData.skuList.splice(index, 1);
    setValues(cloneData);
  };

  const handleRemoveSku = (event: React.MouseEvent<HTMLButtonElement>, index: any) => {
    event.preventDefault();

    const button: HTMLButtonElement = event.currentTarget;

    removeSku(button.id);

    const cloneData = { ...values };
    cloneData.skuList.splice(index, 1);
    setValues(cloneData);
  };

  //To add unit discount field if only set discount(s) is/are already present
  const handleAddUnitDiscount = (index: any) => {
    const cloneData = { ...values };

    cloneData.skuList[index].discountRules.push({
      type: "MIN_UNIT_DISCOUNT",
      unitQuantity: 0,
      unitDiscount: 0,
    });

    setValues(cloneData);
  };

  //To add set discount fields if only unit discount is already present
  const handleAddSetDiscounts = (index: any) => {
    const cloneData = { ...values };

    cloneData.skuList[index].discountRules.push({
      type: "OVER_AMOUNT_DISCOUNT",
      unitQuantity: 0,
      unitDiscount: 0,
    });

    setValues(cloneData);
  };

  //On cards input change
  const handleDynamicPromoChange = (e: any, index: any) => {
    const { name, value, id } = e.target;

    const cloneData = { ...values };

    let type = "";
    let ruleIndex = 0;

    if (id !== undefined) {
      let tempString = id.split("-");
      tempString.forEach((string: any, index: any) => {
        if (string === "MIN_UNIT_DISCOUNT" || string === "OVER_AMOUNT_DISCOUNT") {
          type = string;
        } else {
          ruleIndex = parseInt(string) + 1;
        }
      });
    }

    if (type === "MIN_UNIT_DISCOUNT" || type === "OVER_AMOUNT_DISCOUNT") {
      let discountRules = cloneData.skuList[index].discountRules;

      for (let rule = 0; rule < discountRules.length; rule++) {
        if (discountRules[rule].type === type) {
          if (type === "OVER_AMOUNT_DISCOUNT") {
            discountRules[ruleIndex][name] = Number(value);
          } else if (type === "MIN_UNIT_DISCOUNT") {
            discountRules[ruleIndex][name] = Number(value);
          }
        }
      }
    } else {
      if (name === "salePrice" || name === "salePrice") {
        cloneData.skuList[index][name] = Number(value);
      } else {
        cloneData.skuList[index][name] = value;
      }
    }

    // setDynamicPromo(list);
    setValues(cloneData);
  };

  //Add dynamically Card nested rule input
  const addNestedDiscountRule = (cardIndex: any) => {
    // let list = [...dynamicPromo];
    // list.forEach((item: any, index: any) => {
    //   if (cardIndex === index) {
    //     let discountRules = item.discountRules;
    //     discountRules.push({
    //       type: "OVER_AMOUNT_DISCOUNT",
    //       unitQuantity: 0,
    //       unitDiscount: 0,
    //     });
    //   }
    // });
    // setDynamicPromo(list);
    let cloneData = { ...values };
    cloneData.skuList.forEach((obj: any, index: any, array: any) => {
      if (array[cardIndex] === array[index]) {
        let discountRules = obj.discountRules;
        discountRules.push({
          type: "OVER_AMOUNT_DISCOUNT",
          unitQuantity: 0,
          unitDiscount: 0,
        });
      }
    });
    console.log("cloneData:", cloneData);

    setValues(cloneData);
  };

  //Remove dynamically Card nested rule input
  const removeNestedDiscountRule = (event: any, cardIndex: any) => {
    const { id } = event.target;

    let ruleIndex = parseInt(id) + 1;
    // console.log("ruleIndex:", ruleIndex);
    // const list = [...dynamicPromo];
    // list.forEach((item: any, index: any, array: any) => {
    //   if (cardIndex === index) {
    //     let discountRules = item.discountRules;

    //     discountRules.splice(ruleIndex, 1);
    //   }
    // });
    // setDynamicPromo(list);
    let cloneData = { ...values };
    cloneData.skuList.forEach((obj: any, index: any, array: any) => {
      if (array[cardIndex] === array[index]) {
        let discountRules = obj.discountRules;

        discountRules.splice(ruleIndex, 1);
      }
    });

    setValues(cloneData);
  };

  //Add discountRules to enable mix & match discount
  const addMixDiscountRule = () => {
    let cloneData = { ...values };

    cloneData.discountRules.push({
      type: "OVER_AMOUNT_DISCOUNT",
      unitQuantity: 0,
      unitDiscount: 0,
    });

    setValues(cloneData);
  };

  //Remove dynamically Card nested rule input
  const removeMixDiscountRule = (event: any) => {
    const { id } = event.target;
    console.log("id:", id);

    let ruleIndex = parseInt(id);

    let cloneData = { ...values };
    cloneData.discountRules.splice(ruleIndex, 1);

    setValues(cloneData);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleProductDiscount = (e: any) => {
    const { name, value, id } = e.target;
    const cloneData = { ...values };

    let discountRules = cloneData.discountRules;
    let index = parseInt(id.slice(-1));

    for (let rule = 0; rule < discountRules.length; rule++) {
      const ruleItem = discountRules[rule];

      if (ruleItem.type === "OVER_AMOUNT_DISCOUNT") {
        if (index === rule) {
          ruleItem[name] = Number(value);
        }
      }
    }

    // Assign the updated discountRules array back to the cloned data
    cloneData.discountRules = discountRules;

    setValues(cloneData);
  };

  const handleProductPercentDiscount = (e: any) => {
    const { name, value } = e.target;

    setPercentDiscount((prevState) => ({ ...prevState, [name]: Number(value) }));
  };

  const handlePositionChange = (e: any) => {
    const { value } = e.target;
    setProductPosition(value);
  };

  const handleEdit = (e: any, index: any) => {
    let fileArray: any = [];
    let fileObj = e.target.files[0];

    if (e.target.files) {
      fileArray = Array.from(e.target.files).map((file: any) => URL.createObjectURL(file));

      let tempImageArray = [...imageFiles];
      tempImageArray[index].url = fileArray[0];

      setImageFiles(tempImageArray);
      sendFiles.push(fileObj);
    }
  };

  const handleAddImage = (e: any) => {
    let fileArray: any = [];
    let fileObj = e.target.files[0];

    if (e.target.files) {
      fileArray = Array.from(e.target.files).map((file: any) => URL.createObjectURL(file));

      let tempImageArray = [...imageFiles];
      tempImageArray.push({
        url: fileArray[0],
        name: fileObj.name,
      });

      setImageFiles(tempImageArray);
      sendFiles.push(fileObj);
    }
  };

  const handleRemoveImage = (e: any, index: any) => {
    const { id } = e.target;

    let indexOfObject;

    if (id !== "") {
      indexOfObject = sendFiles.findIndex((obj: any) => {
        return obj.name === id;
      });

      console.log("removeSendIndex:", indexOfObject);
    }

    const cloneImageData = [...imageFiles];
    const cloneSendFiles = [...sendFiles];

    cloneImageData.splice(index, 1);

    if (sendFiles.length > 0) {
      cloneSendFiles.splice(indexOfObject, 1);
      setSendFiles(cloneSendFiles);
    }

    setImageFiles(cloneImageData);
  };

  const backpage = () => {
    navigate("/operations/get-all-inventory");
  };

  const packageUploadData = (file: any) => {
    const formData = new FormData();
    formData.append("file", file); //the actual file to upload
    console.log("formData", formData);
    return formData;
  };

  const uploadImage = async (file: any, pictureArray: any) => {
    try {
      const data = packageUploadData(file);

      const result = await Axios.post(`${url}/rapidcart/admin/oss/uploadImage`, data);

      console.log("Upload Result:", result);

      if (result.data.code === 200) {
        let url = result.data.data.url;
        pictureArray.push({ url: url });
      }
    } catch (err) {
      console.log("uploadImage Error:", err);
    }
  };

  const changePosition = async (sourceId: any, destinationId: any) => {
    try {
      let data = {
        sourceId: sourceId.toString(),
        destinationId,
      };

      const result = Axios.post(`${url}/rapidcart/admin/inventory/order`, data);

      console.log("Change Position Result:", result);
    } catch (err) {
      console.log("Change Position Error:", err);
    }
  };

  const updateProduct = async (product: any, pictureArray: any) => {
    try {
      let data = product;
      let discountRules = product.discountRules;

      // pictureArray will have items if there are newly uploaded images
      if (pictureArray.length > 0) {
        let clonedPicArr = [...imageFiles].map(({ url }) => ({ url }));

        let filteredArray = clonedPicArr.filter((obj) => {
          for (const key in obj) {
            if (!(obj as any)[key].includes("blob")) {
              return obj;
            }
          }
        });

        let modifiedPictureArray = filteredArray.concat(pictureArray);
        data.pictureUrls = modifiedPictureArray;
      } else {
        let clonedPicArr = [...imageFiles];

        data.pictureUrls = clonedPicArr;
      }

      for (let i = 0; i < discountRules.length; i++) {
        const rule = discountRules[i];

        if (rule.unitDiscount === 0 || rule.unitQuantity === 0) {
          data.discountRules = [];
        }
      }

      if (percentDiscount.unitDiscount !== 0 && percentDiscount.unitQuantity !== 0) {
        data.discountRules.push(percentDiscount);
      } else if (percentDiscount.unitDiscount === 0 && percentDiscount.unitQuantity === 0) {
        let updateData = data.discountRules.filter(
          (item: any) => item.type !== "OVER_AMOUNT_PERCENT_DISCOUNT"
        );
        data.discountRules = updateData;
      }

      const result = await Axios.post(`${url}/rapidcart/admin/inventory/update`, data);
      console.log("updateProduct res:", result);
      openSuccessSB();
    } catch (err) {
      console.log("updateProduct Error:", err);
    }
  };

  const updateSku = async (objData: any) => {
    try {
      const { id, name, stock, salePrice, discountRules } = objData;
      let discountArray: any = [];
      let unitPromo: any = [];
      let groupPromo: any = [];

      let data = {
        id,
        name,
        stock: parseInt(stock),
        salePrice: parseFloat(salePrice),
        discountRules: discountArray,
      };

      if (discountRules.length > 0) {
        for (let rule = 0; rule < discountRules.length; rule++) {
          if (discountRules[rule].type === "MIN_UNIT_DISCOUNT") {
            unitPromo.push(discountRules[rule]);
          } else if (discountRules[rule].type === "OVER_AMOUNT_DISCOUNT") {
            groupPromo.push(discountRules[rule]);
          }
        }
      }

      for (let unitItems = 0; unitItems < unitPromo.length; unitItems++) {
        for (let groupItems = 0; groupItems < groupPromo.length; groupItems++) {
          if (
            unitPromo[unitItems].unitQuantity > 0 &&
            unitPromo[unitItems].unitDiscount > 0 &&
            groupPromo[groupItems].unitQuantity > 0 &&
            groupPromo[groupItems].unitDiscount > 0
          ) {
            data.discountRules = [...unitPromo, ...groupPromo];

            if (percentDiscount.unitDiscount !== 0 && percentDiscount.unitQuantity !== 0) {
              data.discountRules.push(percentDiscount);
            }
          } else if (
            unitPromo[unitItems].unitQuantity > 0 &&
            unitPromo[unitItems].unitDiscount > 0 &&
            groupPromo[groupItems].unitQuantity === 0 &&
            groupPromo[groupItems].unitDiscount === 0
          ) {
            data.discountRules = [...unitPromo];

            if (percentDiscount.unitDiscount !== 0 && percentDiscount.unitQuantity !== 0) {
              data.discountRules.push(percentDiscount);
            }
          } else if (
            unitPromo[unitItems].unitQuantity === 0 &&
            unitPromo[unitItems].unitDiscount === 0 &&
            groupPromo[groupItems].unitQuantity > 0 &&
            groupPromo[groupItems].unitDiscount > 0
          ) {
            data.discountRules = [...groupPromo];

            if (percentDiscount.unitDiscount !== 0 && percentDiscount.unitQuantity !== 0) {
              data.discountRules.push(percentDiscount);
            }
          } else if (
            unitPromo[unitItems].unitQuantity === 0 &&
            unitPromo[unitItems].unitDiscount === 0 &&
            groupPromo[groupItems].unitQuantity === 0 &&
            groupPromo[groupItems].unitDiscount === 0
          ) {
            if (percentDiscount.unitDiscount !== 0 && percentDiscount.unitQuantity !== 0) {
              data.discountRules.push(percentDiscount);
            }
          }
        }
      }

      const result = await Axios.post(`${url}/rapidcart/admin/inventorySku/update`, data);
      console.log("Update SKU Result:", result);
    } catch (err) {
      console.log("Update SKU Error:", err);
    }
  };

  const createSku = async (objData: any) => {
    try {
      const { name, stock, salePrice, discountRules } = objData;

      let data = {
        inventoryId: values.id,
        name,
        stock: parseInt(stock),
        salePrice: parseInt(salePrice),
        discountRules,
      };

      discountRules.forEach((eachRule: any) => {
        if (eachRule.type === "MIN_UNIT_DISCOUNT") {
          if (eachRule.unitQuantity === 0 && eachRule.unitDiscount === 0) {
            data.discountRules = data.discountRules.filter(
              (obj: any) => obj.type !== "MIN_UNIT_DISCOUNT"
            );
          }
        }

        if (eachRule.type === "OVER_AMOUNT_DISCOUNT") {
          if (eachRule.unitQuantity === 0 && eachRule.unitDiscount === 0) {
            data.discountRules = data.discountRules.filter(
              (obj: any) => obj.type !== "OVER_AMOUNT_DISCOUNT"
            );
          }
        }
      });

      const result = await Axios.post(`${url}/rapidcart/admin/inventorySku/create`, data);
      console.log("Create Sku Result:", result);
    } catch (err) {
      console.log("Create Sku Error:", err);
    }
  };

  const removeSku = async (id: any) => {
    try {
      let data = {
        id: parseInt(id),
      };

      const result = await Axios.post(`${url}/rapidcart/admin/inventorySku/delete`, data);

      console.log("Remove Sku Result:", result);
    } catch (err) {
      console.log("Remove Sku Error:", err);
    }
  };

  const handleSubmit = async () => {
    const { skuList } = values;

    const isErrorNumber = (n: any) => isNaN(n) || +n < 0;
    const hasErrorSku = skuList.find(n => isErrorNumber(n.salePrice) || isErrorNumber(n.stock));
    if (hasErrorSku) {
      message.error('Please enter the correct SKU stock and SKU selling price, the value must be a number >=0');
      return;
    }

    let pictureArray: any = [];

    if (sendFiles.length > 0) {
      for (const file of sendFiles) {
        await uploadImage(file, pictureArray);
      }
    }

    if (productPosition !== "") {
      await changePosition(values.id, productPosition);
    }

    await updateProduct(values, pictureArray);

    skuList.forEach((obj: any) => {
      if (obj.id) {
        updateSku(obj);
      } else {
        createSku(obj);
      }
    });

    setTimeout(() => backpage(), 500);

    // if (dynamicPromo.length > 0) {
    //   let checkData = [...dynamicPromo];

    //   checkData.forEach((dataObj: any) => {
    //     dataObj.name = values.title;
    //     dataObj.inventoryId = values.id;
    //     let discountRules = dataObj.discountRules;
    //     if (dataObj.salePrice !== 0 && dataObj.stock !== 0) {
    //       //check and remove all rules with 0 value
    //       if (discountRules.length > 0) {
    //         discountRules.forEach((eachRule: any) => {
    //           if (eachRule.type === "MIN_UNIT_DISCOUNT") {
    //             if (eachRule.unitQuantity === 0 && eachRule.unitDiscount === 0) {
    //               dataObj.discountRules = dataObj.discountRules.filter((obj: any) => obj.type !== 'MIN_UNIT_DISCOUNT');
    //             }
    //           }

    //           if (eachRule.type === "OVER_AMOUNT_DISCOUNT") {
    //             if (eachRule.unitQuantity === 0 && eachRule.unitDiscount === 0) {
    //               dataObj.discountRules = dataObj.discountRules.filter((obj: any) => obj.type !== 'OVER_AMOUNT_DISCOUNT');
    //             }
    //           }
    //         });
    //       }
    //       //call api and pass in each dataObj
    //     }

    //     createSku(dataObj);
    //   });
    // }
  };

  useEffect(() => {
    queryOneInventory();
  }, []);

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Product Updated"
      content="Your product has been successfully updated!"
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );
  // console.log("percent:", percentDiscount);
  // console.log("values:", values);
  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Make the changes below
              </MDTypography>
              <MDBox mt={1} mb={2}>
                <MDTypography variant="body2" color="text">
                  Once finished, please press save
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="outlined" color="info" onClick={backpage}>
                  back
                </MDButton>

                <input
                  type="file"
                  name="file"
                  accept="image/*"
                  ref={inputRef}
                  onChange={handleAddImage}
                  style={{ display: "none" }}
                />

                <MDButton
                  variant="gradient"
                  color="warning"
                  style={{ marginLeft: "5px" }}
                  onClick={triggerFileSelect}
                >
                  Add Image
                </MDButton>

                <MDButton
                  variant="gradient"
                  color="info"
                  onClick={handleSubmit}
                  style={{ marginLeft: "5px" }}
                >
                  save
                </MDButton>
                {renderSuccessSB}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          {imageFiles.map((item, index) => (
            <Grid item xs={12} lg={4}>
              <ProductImage
                imageUrl={item.url}
                handleEdit={(e) => handleEdit(e, index)}
                handleRemoveImage={(e) => handleRemoveImage(e, index)}
                key={index}
                id={item.name ? item.name : ""}
              />
            </Grid>
          ))}
          <Grid item xs={12} lg={12}>
            <ProductInfo
              values={values}
              percentDiscount={percentDiscount}
              inputUpdates={handleChange}
              discountUpdates={(e) => handleProductDiscount(e)}
              discountPercentUpdates={(e) => handleProductPercentDiscount(e)}
              addMixRules={addMixDiscountRule}
              removeMixRules={(e) => removeMixDiscountRule(e)}
            />
          </Grid>

          {values.skuList.map((item, index) => (
            <Grid item xs={12} lg={12} key={index}>
              <DynamicPriceCard
                key={`Dynamic-Price-Card-${index}`}
                skuId={item.id}
                promoObjects={item}
                percentDiscount={percentDiscount}
                handleCardRemove={() => handleRemoveCard(index)}
                inputUpdates={(e) => handleDynamicPromoChange(e, index)}
                inputPercentDiscount={(e) => handleProductPercentDiscount(e)}
                addNestedRules={() => addNestedDiscountRule(index)}
                removeNestedRules={(ruleIndex) => removeNestedDiscountRule(ruleIndex, index)}
                handleRemoveSku={(e) => handleRemoveSku(e, index)}
                handleAddSetDiscount={() => handleAddSetDiscounts(index)}
                handleAddUnitDiscount={() => handleAddUnitDiscount(index)}
              />
            </Grid>
          ))}

          <Grid item xs={12} lg={12}>
            <MDButton
              variant="gradient"
              color="dark"
              style={{ marginTop: "10px" }}
              onClick={handleAddCard}
            >
              Add Price Cards
            </MDButton>
          </Grid>

          <Grid item xs={12} lg={12}>
            <ProductPosition
              sourceId={values.id}
              destinationId={productPosition}
              inputUpdates={handlePositionChange}
            />
          </Grid>

          <Grid item xs={12} lg={12}>
            <MDButton
              variant="gradient"
              color="info"
              onClick={handleSubmit}
              style={{ marginLeft: "5px" }}
            >
              save
            </MDButton>
          </Grid>
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateProduct;
