// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

interface Props {
  skuName: string;
  skuSalePrice: string;
  buyCount: number;
}

function ProductCard({ skuName, skuSalePrice, buyCount }: Props): JSX.Element {

  return (
    <Card>
      <MDBox p={3}>
      <MDTypography variant="h5">Product Information</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField 
              name="skuName"
              value={skuName} 
              type="text" 
              label="Product Name" 
              disabled
            />
          </Grid>
        </Grid>
      </MDBox>
      
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField 
              name="skuSalePrice"
              value={skuSalePrice} 
              type="text" 
              label="Purchased Quantity" 
              disabled
            />
          </Grid>
        </Grid>
      </MDBox>

      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField 
              name="buyCount"
              value={buyCount} 
              type="text" 
              label="Sale Price" 
              disabled
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
    </Card>
  );
}

ProductCard.defaultProps = {
};

export default ProductCard;