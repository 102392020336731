import { useRef } from "react";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Images
// import productImage from "assets/images/products/product-11.jpg";

interface Props {
  id?: string;
  key?: number;
  imageUrl: string;
  imageFiles?: any[];
  setImageFiles?: (item: any) => void;
  handleEdit: (event: any) => void;
  handleRemoveImage: (event: any) => void;
}

function ProductImage({
  handleEdit,
  handleRemoveImage,
  imageUrl,
  imageFiles,
  setImageFiles,
  id,
}: Props): JSX.Element {
  //To reference input tag when click Edit button
  const inputRef = useRef<HTMLInputElement>(null);

  const triggerFileSelect = () => {
    if (null !== inputRef.current) {
      inputRef.current.click();
    }
  };

  return (
    <Card
      sx={{
        "&:hover .card-header": {
          transform: "translate3d(0, -50px, 0)",
        },
      }}
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header"
        sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        <MDBox
          component="img"
          src={imageUrl}
          alt="Product Image"
          borderRadius="lg"
          shadow="sm"
          width="100%"
          height="100%"
          position="relative"
          zIndex={10}
          mb={2}
        />
      </MDBox>
      <MDBox textAlign="center" pt={2} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={-11}
          position="relative"
          zIndex={1}
        >
          <MDBox mr={1}>
            <MDButton
              variant="gradient"
              color="error"
              size="small"
              id={id}
              onClick={handleRemoveImage}
            >
              remove
            </MDButton>
          </MDBox>

          <MDBox mr={1}>
            <input
              type="file"
              name="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleEdit}
              style={{ display: "none" }}
            />
            <MDButton variant="gradient" color="info" size="small" onClick={triggerFileSelect}>
              edit
            </MDButton>
          </MDBox>
          {/* <MDButton variant="outlined" color="dark" size="small">
            remove
          </MDButton> */}
        </MDBox>
        <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 4 }}>
          Product Image
        </MDTypography>
        {/* <MDTypography variant="body2" color="text" sx={{ mt: 1.5, mb: 1 }}>
          To change the image for this product, please hover the image and click on either &#8220;Edit&#8221; or &#8220;Remove&#8221;
        </MDTypography> */}
        <MDTypography variant="body2" color="text" sx={{ mt: 1.5, mb: 1 }}>
          To change the image for this product, please hover the image and click on
          &#8220;Edit&#8221;
        </MDTypography>
      </MDBox>
    </Card>
  );
}

ProductImage.defaultProps = {
  key: 0,
  imageUrl: "",
  imageFiles: [],
  setImageFiles: () => {},
  handleEdit: () => {},
};

export default ProductImage;
