import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "./components/DataTable";
import IdCell from "./components/idCell";
import DefaultCell from "./components/DefaultCell";
import ActionCell from "./components/ActionCell";

//API
import Axios from "axios";
import { url } from "../../../utils/constant";

function Applications(): JSX.Element {

  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const handleMore = (id: any) => {
    console.log('Accept:', id);
    navigate(`/super-admin/applicant/${id}`)
  };

  const dataTableData:any = {
    columns: [
      { 
        Header: "id", 
        accessor: "id",  
        Cell: ({ value }: any) => <IdCell id={value}/> 
      },
      { 
        Header: "companyName", 
        accessor: "companyName",
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      { 
        Header: "host", 
        accessor: "host",
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      { 
        Header: "email", 
        accessor: "email",
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      { 
        Header: "auditStatus", 
        accessor: "auditStatus",
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      { 
        Header: "actions",
        Cell: ({ row }: any) => <ActionCell 
                                    id={row.original.id} 
                                    more={() => handleMore(row.original.id)} 
                                  />,
      },
    ],

    rows: data
  };

  const queryAllApplications = async () => {
    try {
      const result = await Axios.post(`${url}/rapidcart/admin/apply/queryAll`, { pageSize: 10000 });

      let data = result.data.data.lists;
      console.log('Get All App Res:', data);

      setData(data);
      
    } catch (err) {
      console.log('Get All App Error:', err);
    }
  };

  useEffect(() => {
    queryAllApplications();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar/>

      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Current Deployment Application
              </MDTypography>

              {/* <MDTypography variant="button" color="text">
                A lightweight, extendable, dependency-free javascript HTML table plugin.
              </MDTypography> */}

            </MDBox>
            <DataTable table={dataTableData} canSearch/>
          </Card>
        </MDBox>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default Applications;