// @mui material components
import Grid from "@mui/material/Grid";
// import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";

interface Props {
  values: { [key: string]: any };
  inputUpdates: (event: any) => void;
}

function ProductInfo({ values, inputUpdates  }: Props): JSX.Element {
  const { 
    title, 
    description, 
    description2, 
    description3,
    promotionDescription,
    salesPolicy,
  } = values;

  return (
    <MDBox>
      <MDTypography variant="h5">Product Information</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField 
              name="title"
              value={title} 
              type="text" 
              label="Title" 
              onChange={inputUpdates}
            />
          </Grid>
          {/* <Grid item xs={12} sm={6}>
            <FormField type="text" label="Weight" />
          </Grid> */}
        </Grid>
      </MDBox>
      <MDBox mt={2}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="flex">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Description 1
              </MDTypography>
            </MDBox>
            <MDInput 
              name="description"
              value={description} 
              onChange={inputUpdates} 
              multiline 
              rows={3}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="flex">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Description 2&nbsp;&nbsp;
                <MDTypography variant="caption" color="text">
                  (optional)
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDInput 
              name="description2" 
              value={description2}
              onChange={inputUpdates} 
              multiline 
              rows={3}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="flex">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Description 3&nbsp;&nbsp;
                <MDTypography variant="caption" color="text">
                  (optional)
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDInput 
              name="description3" 
              value={description3}
              onChange={inputUpdates} 
              multiline 
              rows={3}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="flex">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Promotion Description&nbsp;&nbsp;
                <MDTypography variant="caption" color="text">
                  (optional)
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDInput 
              name="promotionDescription" 
              value={promotionDescription}
              onChange={inputUpdates} 
              multiline 
              rows={3}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="flex">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Sales Policy&nbsp;&nbsp;
                <MDTypography variant="caption" color="text">
                  (optional)
                </MDTypography>
              </MDTypography>
            </MDBox>
            <MDInput 
              name="salesPolicy" 
              value={salesPolicy}
              onChange={inputUpdates} 
              multiline 
              rows={3}
              fullWidth
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <MDBox mb={3}>
              <MDBox mb={2} display="inline-block">
                <MDTypography
                  component="label"
                  variant="button"
                  fontWeight="regular"
                  color="text"
                  textTransform="capitalize"
                >
                  Category
                </MDTypography>
              </MDBox>
              <Autocomplete
                defaultValue="Clothing"
                options={["Clothing", "Electronics", "Furniture", "Others", "Real Estate"]}
                renderInput={(params) => <MDInput {...params} variant="standard" />}
              />
            </MDBox>
            <MDBox mb={2} display="inline-block">
              <MDTypography
                component="label"
                variant="button"
                fontWeight="regular"
                color="text"
                textTransform="capitalize"
              >
                Size
              </MDTypography>
            </MDBox>
            <Autocomplete
              defaultValue="Medium"
              options={["Extra Large", "Extra Small", "Large", "Medium", "Small"]}
              renderInput={(params) => <MDInput {...params} variant="standard" />}
            />
          </Grid> */}
        </Grid>
      </MDBox>
    </MDBox>
  );
}

ProductInfo.defaultProps = {
  values: {
    title: "",
    description: "",
    description2: "",
    description3: "",
  }
};

export default ProductInfo;