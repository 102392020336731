import { useMemo } from "react";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDDropzone from "components/MDDropzone";

interface Props {
  handleFilesChange: (file: any) => void;
  handleFilesRemoved: (file: any) => void;
}

function Media({ handleFilesChange, handleFilesRemoved }: Props): JSX.Element {

  return (
    <MDBox>
      <MDTypography variant="h5">Product Images</MDTypography>
      <MDBox mt={3}>
        <MDBox mb={1} ml={0.5} lineHeight={0} display="inline-block">
          <MDTypography component="label" variant="button" fontWeight="regular" color="text">
            At least 1 or up to 5 images
          </MDTypography>
        </MDBox>
        {useMemo(
          () => (
            <MDDropzone 
              options={{ 
                addRemoveLinks: true,
                maxFiles: 5,
              }}
              uploadFiles={handleFilesChange}
              removeFile={handleFilesRemoved}
            />
          ),
          []
        )}
      </MDBox>
    </MDBox>
  );
}

Media.defaultProps = {
  handleFilesChange: () => {},
  handleFilesRemoved: () => {},
};

export default Media;