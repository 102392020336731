// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

interface Props {
  values: { [key: string]: any };
}

function SalesInfo({ values }: Props): JSX.Element {
  const {
    createTime,
    statusFlag,
    updateTime,
    name,
    phone,
    email,
    address,
    paymentMethod,
    payNetwork,
    transactionId,
    transactionTotal,
  } = values;

  return (
    <Card>
      <MDBox p={3}>
        <MDTypography variant="h5">Customer Detail</MDTypography>
        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12}>
              <FormField
                name="transactionId"
                value={transactionId}
                type="text"
                label="Transaction ID"
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormField
                name="createTime"
                value={createTime}
                type="text"
                label="Creation Date & Time"
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormField
                name="statusFlag"
                value={statusFlag}
                type="text"
                label="Transaction Status"
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormField
                name="updateTime"
                value={updateTime}
                type="text"
                label="Completion Date & Time"
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormField name="name" value={name} type="text" label="Customer Name" disabled />
            </Grid>
            {/* <Grid item xs={12} sm={6}>
            <FormField type="text" label="Weight" />
          </Grid> */}
            <Grid item xs={12} sm={12}>
              <FormField name="phone" value={phone} type="text" label="Customer Phone" disabled />
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormField name="email" value={email} type="text" label="Customer Email" disabled />
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormField
                name="address"
                value={address}
                type="text"
                label="Customer Address"
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormField
                name="paymentMethod"
                value={paymentMethod}
                type="text"
                label="Payment Method"
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormField
                name="payNetwork"
                value={payNetwork}
                type="text"
                label="Payment Network"
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormField
                name="transactionTotal"
                value={transactionTotal}
                type="text"
                label="Transaction Total"
                disabled
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

SalesInfo.defaultProps = {
  values: {
    title: "",
    statusFlag: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    paymentMethod: "",
    transactionId: "",
    transactionTotal: "",
  },
};

export default SalesInfo;
