import { useRef } from "react";

// @material-ui core components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

import { styled } from "@mui/material/styles";
import Switch, { SwitchProps } from "@mui/material/Switch";

// Settings page components
import FormField from "layouts/pages/account/components/FormField";

// Data
// import selectData from "layouts/pages/account/settings/components/BasicInfo/data/selectData";

interface Props {
  webConfig: { [key: string]: any };
  fileName: string;
  // faviconName: string;
  inputUpdates: (event: any) => void;
  handleEdit: (event: any) => void;
  handleSwitch: (event: any) => void;
  // handleFavicon: (event: any) => void;
  submit: (event: any) => void;
}

function WebConfig({
  webConfig,
  fileName,
  inputUpdates,
  handleEdit,
  handleSwitch,
  submit,
}: Props): JSX.Element {
  const { logo, name, email, phone, homePageUrl, showNavItems } = webConfig;

  const IOSSwitch = styled((props: SwitchProps) => (
    <Switch focusVisibleClassName=".Mui-focusVisible" disableRipple {...props} />
  ))(({ theme }) => ({
    width: 42,
    height: 26,
    padding: 4,
    "& .MuiSwitch-switchBase": {
      padding: 0,
      margin: 0,
      transitionDuration: "300ms",
      "&.Mui-checked": {
        transform: "translateX(16px)",
        color: "#fff",
        "& + .MuiSwitch-track": {
          backgroundColor: theme.palette.mode === "dark" ? "#2ECA45" : "#65C466",
          opacity: 1,
          border: 0,
        },
        "&.Mui-disabled + .MuiSwitch-track": {
          opacity: 0.5,
        },
      },
      "&.Mui-focusVisible .MuiSwitch-thumb": {
        color: "#33cf4d",
        border: "6px solid #fff",
      },
      "&.Mui-disabled .MuiSwitch-thumb": {
        color: theme.palette.mode === "light" ? theme.palette.grey[100] : theme.palette.grey[600],
      },
      "&.Mui-disabled + .MuiSwitch-track": {
        opacity: theme.palette.mode === "light" ? 0.7 : 0.3,
      },
    },
    "& .MuiSwitch-thumb": {
      boxSizing: "border-box",
      width: 22,
      height: 22,
    },
    "& .MuiSwitch-track": {
      borderRadius: 26 / 2,
      backgroundColor: theme.palette.mode === "light" ? "#E9E9EA" : "#39393D",
      opacity: 1,
      transition: theme.transitions.create(["background-color"], {
        duration: 500,
      }),
    },
  }));

  // const faviconRequirements = [
  //   "Please use the following link and upload your company logo",
  //   "https://favicon.io/favicon-converter/",
  //   "Convert your logo picture file to Favicon and download the file",
  //   "Unzip the downloaded file and upload the file that is named: favicon.io",
  //   "Submit this form once everything is updated",
  // ];

  // const renderFaviconRequirements = faviconRequirements.map((item, key) => {
  //   const itemKey = `element-${key}`;

  //   return (
  //     <MDBox key={itemKey} component="li" color="text" fontSize="1.25rem" lineHeight={1}>
  //       <MDTypography variant="button" color="text" fontWeight="regular" verticalAlign="middle">
  //         {item}
  //       </MDTypography>
  //     </MDBox>
  //   );
  // });

  //To reference input tag when click Edit button
  const inputRef = useRef<HTMLInputElement>(null);
  // const inputFavRef = useRef<HTMLInputElement>(null);

  const triggerFileSelect = () => {
    if (null !== inputRef.current) {
      inputRef.current.click();
    }
  };

  // const triggerFaviconSelect = () => {
  //   if (null !== inputFavRef.current) {
  //     inputFavRef.current.click();
  //   }
  // };

  return (
    <Card id="web-config" sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Website Configuration</MDTypography>
      </MDBox>
      <MDBox component="form" pb={3} px={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Website Name"
              name="name"
              value={name}
              placeholder="Website Name"
              onChange={inputUpdates}
            />
          </Grid>
          <Grid item xs={12} sm={6}>
            <FormField
              label="Email"
              name="email"
              value={email}
              inputProps={{ type: "email" }}
              placeholder="example@email.com"
              onChange={inputUpdates}
            />
          </Grid>
          {/* <Grid item xs={12}>
            <Grid container spacing={3}>
              <Grid item xs={12} sm={4}>
                <Autocomplete
                  defaultValue="Male"
                  options={selectData.gender}
                  renderInput={(params) => (
                    <FormField {...params} label="I'm" InputLabelProps={{ shrink: true }} />
                  )}
                />
              </Grid>
              <Grid item xs={12} sm={8}>
                <Grid container spacing={3}>
                  <Grid item xs={12} sm={5}>
                    <Autocomplete
                      defaultValue="February"
                      options={selectData.birthDate}
                      renderInput={(params) => (
                        <FormField
                          {...params}
                          label="Birth Date"
                          InputLabelProps={{ shrink: true }}
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={4}>
                    <Autocomplete
                      defaultValue="1"
                      options={selectData.days}
                      renderInput={(params) => (
                        <FormField {...params} InputLabelProps={{ shrink: true }} />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12} sm={3}>
                    <Autocomplete
                      defaultValue="2021"
                      options={selectData.years}
                      renderInput={(params) => (
                        <FormField {...params} InputLabelProps={{ shrink: true }} />
                      )}
                    />
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid> */}
          <Grid item xs={12} sm={6}>
            <FormField
              label="Phone Number"
              name="phone"
              value={phone}
              placeholder="852 1234 5678"
              inputProps={{ type: "phone" }}
              onChange={inputUpdates}
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              label="Logo Url (Display Only)"
              name="logo"
              value={logo}
              placeholder="www.logo.com"
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              label="Uploaded Logo File Name"
              value={fileName}
              placeholder="File name will display if a file is attached"
              disabled
            />
          </Grid>

          <Grid item xs={12} sm={6}>
            <FormField
              label="Home Page Url"
              name="homePageUrl"
              value={homePageUrl}
              inputProps={{ type: "homePageUrl" }}
              placeholder="www.example.com"
              onChange={inputUpdates}
            />
          </Grid>

          {/* <Grid item xs={12} sm={6}>
            <FormField
              label="Uploaded Favicon File Name"
              value={faviconName}
              placeholder="File name will display if a file is attached"
              disabled
            />
          </Grid> */}
          {/* <Grid item xs={12} md={6}>
            <FormField label="Language" placeholder="English" />
          </Grid>
          <Grid item xs={12} md={6}>
            <Autocomplete
              multiple
              defaultValue={["react", "angular"]}
              options={selectData.skills}
              renderInput={(params) => <FormField {...params} InputLabelProps={{ shrink: true }} />}
            />
          </Grid> */}
        </Grid>
        <MDBox
          mt={1}
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
        >
          <input
            type="file"
            name="file"
            accept="image/*"
            ref={inputRef}
            onChange={handleEdit}
            style={{ display: "none" }}
          />

          <MDButton variant="gradient" color="dark" size="small" onClick={triggerFileSelect}>
            Upload Logo
          </MDButton>

          {/* <input 
            type="file" 
            name="file"
            accept="image/*"
            ref={inputFavRef}
            onChange={handleFavicon}
            style={{ display: 'none' }}
          /> */}

          {/* <MDButton 
            variant="gradient" 
            color="dark" 
            size="small"
            onClick={triggerFaviconSelect}
          >
            Upload FavIcon
          </MDButton> */}

          {/* <MDButton 
            variant="gradient" 
            color="dark" 
            size="small"
            onClick={submit}
          >
            Submit
          </MDButton> */}
        </MDBox>

        {/* <MDBox mt={6} mb={1}>
          <MDTypography variant="h5">Favicon requirements</MDTypography>
        </MDBox>
        <MDBox mb={1}>
          <MDTypography variant="body2" color="text">
            **Favicon file must be a '.ico' file** Please follow this guide below
          </MDTypography>
        </MDBox> */}
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="flex-end"
          flexWrap="wrap"
          mt="16px"
        >
          {/* <MDBox component="ul" m={0} pl={3.25} mb={{ xs: 8, sm: 0 }}>
            {renderFaviconRequirements}
          </MDBox> */}
          <MDBox>
            <MDBox>
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Show Navbar Items
              </MDTypography>
            </MDBox>

            <MDBox display="flex" justifyContent="space-between" alignItems="center">
              {/* <MDButton variant="gradient" color="dark" size="small">
                Submit
              </MDButton> */}
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Off
              </MDTypography>
              <IOSSwitch
                checked={showNavItems}
                inputProps={{ "aria-label": "controlled" }}
                onChange={handleSwitch}
                sx={{ m: 1 }}
              />
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                On
              </MDTypography>
            </MDBox>
          </MDBox>

          <MDBox ml="auto">
            <MDButton variant="gradient" color="dark" size="small" onClick={submit}>
              Submit
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </Card>
  );
}

WebConfig.defaultProps = {
  webConfig: {
    logo: "",
    name: "",
    email: "",
    phone: "",
  },
  fileName: "",
  faviconName: "",
};

export default WebConfig;
