// @mui material components
import Grid from "@mui/material/Grid";
// import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
// import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";

interface Props {
  values: { [key: string]: any };
  inputUpdates: (event: any) => void;
  groupPromo: any[];
  unitDiscountPromo: any[];
  handleGroupPromo: (event: any) => void;
  handleGroupPromoRemove: (event: any) => void;
  handleGroupPromoChange: (event: any, index: any) => void;
  handleUnitDiscountChange: (event: any, index: any) => void;
}

function Pricing({ 
  groupPromo, 
  values,
  unitDiscountPromo,
  inputUpdates,
  handleGroupPromo,
  handleGroupPromoRemove,
  handleGroupPromoChange,
  handleUnitDiscountChange,
}: Props): JSX.Element {
  const { 
    price, 
    quantity,
  } = values;

  return (
    <MDBox>
      <MDTypography variant="h5">Pricing</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <FormField 
              name="price"
              value={price} 
              type="text" 
              label="Price" 
              placeholder="0.00" 
              onChange={inputUpdates}
            />
          </Grid>
          {/* <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
            <Autocomplete
              defaultValue="USD"
              options={["BTC", "CNY", "EUR", "GBP", "INR", "USD"]}
              renderInput={(params) => <MDInput {...params} variant="standard" />}
            />
          </Grid> */}
          <Grid item xs={12} sm={9}>
            <FormField 
              name="quantity"
              value={quantity} 
              type="text" 
              label="Quantity" 
              placeholder="0.00" 
              onChange={inputUpdates}
            />
          </Grid>
        </Grid>
      </MDBox>
      {/* <MDBox mt={1}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <MDBox my={2} display="inline-block">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Tags
              </MDTypography>
            </MDBox>
            <Autocomplete
              multiple
              defaultValue={["In Stock", "Out of Stock"]}
              options={["Black Friday", "Expired", "Out of Stock", "In Stock", "Sale"]}
              renderInput={(params) => <MDInput {...params} variant="standard" />}
            />
          </Grid>
        </Grid>
      </MDBox> */}

      <MDTypography variant="h5" mt={3}>Discounts (Optional)</MDTypography>
      <MDBox mt={3}>
        {/* <Grid container spacing={3}>
          <Grid item xs={12} sm={3}>
            <FormField 
              name="minQuantity"
              value={minQuantity} 
              type="text" 
              label="Min Quantity" 
              placeholder="0" 
              onChange={inputUpdates}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField 
              name="discountedUnitPrice"
              value={discountedUnitPrice} 
              type="text" 
              label="Discounted Unit Price" 
              placeholder="0.00" 
              onChange={inputUpdates}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField 
              name="unitQuantityA"
              value={unitQuantityA} 
              type="text" 
              label="Unit Quantity A" 
              placeholder="0" 
              onChange={inputUpdates}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField 
              name="unitDiscountA"
              value={unitDiscountA} 
              type="text" 
              label="Unit Discount A" 
              placeholder="0.00" 
              onChange={inputUpdates}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField 
              name="unitQuantityB"
              value={unitQuantityB} 
              type="text" 
              label="Unit Quantity B" 
              placeholder="0" 
              onChange={inputUpdates}
            />
          </Grid>
          <Grid item xs={12} sm={3}>
            <FormField 
              name="unitDiscountB"
              value={unitDiscountB} 
              type="text" 
              label="Unit Discount B" 
              placeholder="0.00" 
              onChange={inputUpdates}
            />
          </Grid>
          <Grid item xs={12} sm={4} sx={{ mt: 2 }}>
            <Autocomplete
              defaultValue="USD"
              options={["BTC", "CNY", "EUR", "GBP", "INR", "USD"]}
              renderInput={(params) => <MDInput {...params} variant="standard" />}
            />
          </Grid>
        </Grid> */}

        <MDTypography variant="h6" mt={3}>Per Unit Discount(s)</MDTypography>
        <MDTypography variant="h6" mt={1}>Original price will be discounted to the set 'unit price' if quantity added to cart is equivalent or greater</MDTypography>
        
        <MDBox mt={3}>
          {unitDiscountPromo.map((unitPromo: any, index: any) => (
            <>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={4}>
                  <FormField 
                    type="text" 
                    label="Unit Quantity" 
                    placeholder="0.00"
                    name="unitQuantity"
                    value={unitPromo.unitQuantity}
                    onChange={(e: any) => handleUnitDiscountChange(e, index)}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormField 
                    type="text" 
                    label="Unit Price" 
                    placeholder="0.00"
                    name="unitDiscount"
                    value={unitPromo.unitDiscount}
                    onChange={(e: any) => handleUnitDiscountChange(e, index)}
                  />
                </Grid>
              </Grid> 
            </>
          ))}
        </MDBox>

        <MDTypography variant="h6" mt={3}>Per Set Discount(s) - Up to 4 sets</MDTypography>
        <MDTypography variant="h6" mt={1}>
          Original price will be discounted to the 'set price' if quantity added to cart is equivalent to the 'set quantity'
        </MDTypography>
        <MDTypography variant="h6" mt={1}>
          If multiple sets are added, we will calculate the best offer to your customers
        </MDTypography>

        <MDBox mt={3}>
          {groupPromo.map((setPromo: any, index: any) => (
            <>
              <Grid container spacing={3} key={index}>
                <Grid item xs={12} sm={4}>
                  <FormField 
                    type="text" 
                    label="Set Quantity" 
                    placeholder="0.00"
                    name="unitQuantity"
                    value={setPromo.unitQuantity}
                    onChange={(e: any) => handleGroupPromoChange(e, index)}
                  />
                </Grid>

                <Grid item xs={12} sm={4}>
                  <FormField 
                    type="text" 
                    label="Set Price" 
                    placeholder="0.00"
                    name="unitDiscount"
                    value={setPromo.unitDiscount}
                    onChange={(e: any) => handleGroupPromoChange(e, index)}
                  />
                </Grid>

                {groupPromo.length > 1 && (
                  <MDButton 
                    variant="gradient" 
                    color="dark" 
                    size="small"
                    style={{ marginLeft: "10px", marginTop: "30px", height: "30px" }}
                    onClick={() => handleGroupPromoRemove(index)}
                  >
                    Remove
                  </MDButton>
                )}
              </Grid>
            
              {groupPromo.length - 1 === index && groupPromo.length < 4 && (
                <MDBox 
                  display="flex" 
                  justifyContent="space-between" 
                  alignItems="flex-start" 
                  flexWrap="wrap"
                  style={{ marginTop: "10px" }}
                >
                  <MDButton 
                    variant="gradient" 
                    color="dark" 
                    size="small"
                    onClick={handleGroupPromo}
                  >
                    Add More
                  </MDButton>
                </MDBox>
              )}
            </>
          ))}
        </MDBox>
      </MDBox>
    </MDBox>
  );
}

Pricing.defaultProps = {
  values: {
    price: "",
    quantity: "",
    minQuantity: "",
    discountedUnitPrice: "",
    unitQuantityA: "",
    unitDiscountA: "",
    unitQuantityB: "",
    unitDiscountB: "",
  },
  groupPromo: [
    {
      type: "OVER_AMOUNT_DISCOUNT",
      unitQuantity: 0,
      unitDiscount: 0,
    },
  ],
  unitDiscountPromo: [
    {
      type: "MIN_UNIT_DISCOUNT",
      unitQuantity: 0,
      unitDiscount: 0,
    },
  ],
};

export default Pricing;