// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Images
// import productImage from "assets/images/products/product-11.jpg";

interface Props {
  imageFiles?: any[];
}

function PictureProof({ imageFiles }: Props): JSX.Element {

  let imageUrl = imageFiles[0].url;

  return (
    <Card
      // sx={{
      //   "&:hover .card-header": {
      //     transform: "translate3d(0, -50px, 0)",
      //   },
      // }}
    >
      <MDBox
        position="relative"
        borderRadius="lg"
        mt={-3}
        mx={2}
        className="card-header"
        sx={{ transition: "transform 300ms cubic-bezier(0.34, 1.61, 0.7, 1)" }}
      >
        <MDBox
          component="img"
          src={imageUrl}
          alt="Product Image"
          borderRadius="lg"
          shadow="sm"
          width="100%"
          height="100%"
          position="relative"
          zIndex={10}
          mb={2}
        />
        
      </MDBox>
      <MDBox textAlign="center" pt={2} pb={3} px={3}>
        <MDBox
          display="flex"
          justifyContent="center"
          alignItems="center"
          mt={-11}
          position="relative"
          zIndex={1}
        >
          {/* <MDBox mr={1}>
            <input 
              type="file" 
              name="file"
              accept="image/*"
              ref={inputRef}
              onChange={handleEdit}
              style={{ display: 'none' }}
            />
            <MDButton 
              variant="gradient" 
              color="info" 
              size="small"
              onClick={triggerFileSelect}
            >
              edit
            </MDButton>
          </MDBox>  */}
        </MDBox>
        <MDTypography variant="h5" fontWeight="regular" sx={{ mt: 4 }}>
          Picture Proof
        </MDTypography>
        {/* <MDTypography variant="body2" color="text" sx={{ mt: 1.5, mb: 1 }}>
          To change the image for this product, please hover the image and click on either &#8220;Edit&#8221; or &#8220;Remove&#8221;
        </MDTypography> */}
        <MDTypography variant="body2" color="text" sx={{ mt: 1.5, mb: 1 }}>
          The image shown is the proof of purchase
        </MDTypography>
      </MDBox>
    </Card>
  );
}

PictureProof.defaultProps = {
  imageFiles: [],
};

export default PictureProof;