/**
=========================================================
* Material Dashboard 2 PRO React TS - v1.0.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-dashboard-2-pro-react-ts
* Copyright 2022 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// react-router-dom components
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";
// import Checkbox from "@mui/material/Checkbox";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Authentication layout components
import CoverLayout from "layouts/authentication/components/CoverLayout";

// Images
import bgImage from "assets/images/bg-sign-up-cover.jpeg";

//API
import Axios from "axios";
import { url } from "../../../../utils/constant";

function Cover(): JSX.Element {

  const navigate = useNavigate();

  const [values, setValues] = useState({
    companyName: '',
    host: '',
    email: '',
  });

  const [apiErrorMsg, setApiErrorMsg] = useState("");

  const [apiErrorSB, setApiErrorSB] = useState<boolean>(false);
  const [errorSB, setErrorSB] = useState<boolean>(false);
  const [success, setSuccess] = useState(undefined);

  const openApiErrorSB = () => setApiErrorSB(true);
  const closeApiErrorSB = () => setApiErrorSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const renderApiErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Submission Error"
      content={apiErrorMsg}
      dateTime=""
      open={apiErrorSB}
      onClose={closeApiErrorSB}
      close={closeApiErrorSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Missing Fields"
      content="You must complete all mandatory fields!"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  const createApplication = async (data: any) => {
    try {
      const result = await Axios.post(`${url}/rapidcart/admin/apply/create`, data);
      console.log('Create Application Success:', result);

      if (result.data.status === "failed") {
        setApiErrorMsg(result.data.message);
        openApiErrorSB();
        return;
      }

      setSuccess(true);

      setTimeout(() => {
        navigate('/sign-in/pw-change');
      }, 1000);

    } catch (err) {
      console.log('Create Application Error:', err);

      setSuccess(false);
    }
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleSubmit = (e: any) => {
    e.preventDefault();

    if (values.companyName === "" 
        || values.email === "" 
        || values.host === ""
        )
    {
      openErrorSB();
      return
    }

    createApplication(values);
  };

  console.log('values:', values);

  const successMessage = <MDBox pt={4} pb={3} px={3}>
                            <MDBox mt={3} mb={1} textAlign="center">
                              <MDTypography display="block" variant="button" color="text" my={1}>
                                Your application has been submitted! An email with your login credentials will be sent to you.
                              </MDTypography>
                            </MDBox>
                          </MDBox>
  
  const unSuccessfulMessage = <MDBox pt={4} pb={3} px={3}>
                            <MDBox mt={3} mb={1} textAlign="center">
                              <MDTypography display="block" variant="button" color="text" my={1}>
                                There seems to be an error, please try again later.
                              </MDTypography>
                            </MDBox>
                          </MDBox>

  return (
    <CoverLayout image={bgImage}>
      <Card>
        <MDBox
          variant="gradient"
          bgColor="info"
          borderRadius="lg"
          coloredShadow="success"
          mx={2}
          mt={-3}
          p={3}
          mb={1}
          textAlign="center"
        >
          <MDTypography variant="h4" fontWeight="medium" color="white" mt={1}>
            Application Form
          </MDTypography>
          <MDTypography display="block" variant="button" color="white" my={1}>
            Please enter all relevant fields before submitting
          </MDTypography>
        </MDBox>
        {
          success === undefined && (
            <MDBox pt={4} pb={3} px={3}>
              <MDBox component="form" role="form" onSubmit={handleSubmit}>
                <MDBox mb={2}>
                  <MDInput 
                    type="text" 
                    name="companyName"
                    onChange={handleChange}
                    label="Company Name" 
                    variant="standard" 
                    fullWidth 
                  />
                </MDBox>
                {/* <MDBox mb={2}>
                  <MDInput type="email" label="Email" variant="standard" fullWidth />
                </MDBox> */}
                <MDBox mb={2}>
                  <MDInput 
                    type="text" 
                    name="host"
                    onChange={handleChange}
                    label="Hosting Name" 
                    variant="standard" 
                    fullWidth 
                  />
                </MDBox>
                <MDBox mb={2}>
                  <MDInput 
                    type="text" 
                    name="email"
                    onChange={handleChange}
                    label="Email Address" 
                    variant="standard" 
                    fullWidth 
                  />
                </MDBox>
                {/* <MDBox display="flex" alignItems="center" ml={-1}>
                  <Checkbox />
                  <MDTypography
                    variant="button"
                    fontWeight="regular"
                    color="text"
                    sx={{ cursor: "pointer", userSelect: "none", ml: -1 }}
                  >
                    &nbsp;&nbsp;I agree the&nbsp;
                  </MDTypography>
                  <MDTypography
                    component="a"
                    href="#"
                    variant="button"
                    fontWeight="bold"
                    color="info"
                    textGradient
                  >
                    Terms and Conditions
                  </MDTypography>
                </MDBox> */}
                <MDBox mt={4} mb={1}>
                  <MDButton variant="gradient" color="info" type="submit" fullWidth>
                    Submit
                  </MDButton>
                </MDBox>
              </MDBox>
              {renderApiErrorSB}
              {renderErrorSB}
            </MDBox>
          )
        }

        {
          success === true && (
            <>
              {successMessage}
            </>
          )
        }  

        {
          success === false && (
            <>
              {unSuccessfulMessage}
            </>
          )
        }  
        
        <MDBox mt={3} mb={1} textAlign="center">
          <MDTypography variant="button" color="text">
            Already have an account?{" "}
            <MDTypography
              component={Link}
              to="/sign-in"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign In
            </MDTypography>

            {/* <MDTypography
              component={Link}
              to="/sign-in/pw-change"
              variant="button"
              color="info"
              fontWeight="medium"
              textGradient
            >
              Sign In & Password Change
            </MDTypography> */}
          </MDTypography>
        </MDBox>
      </Card>
    </CoverLayout>
  );
}

export default Cover;
