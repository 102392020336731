import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { DatePicker, Button as AntdButton, message } from 'antd';
// @mui material components
import { Card, Checkbox, Radio, RadioGroup, FormControlLabel, Button } from "@mui/material";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "./components/DataTable";
import IdCell from "./components/idCell";
import DefaultCell from "./components/DefaultCell";
import ActionCell from "./components/ActionCell";
import { exportExcel } from "../../../utils/export2excel";

//API
import Axios from "axios";
import { url } from "../../../utils/constant";

const ExportTableConfig = {
	訂單ID: "id",
	訂單流水號: "customId",
	TransactionID: "transactionId",
	OutTransactionId: "outTransactionId",
	訂單狀態: "statusFlag",
	姓名: "name",
	聯絡人電話: "phone",
	電郵: "email",
	送貨地址: "address",
	如何得知這個產品: "chInfoText",
	付款方式: "paymentMethod",
	支付方式: "payNetwork",
	數量: "quantity",
	用戶付款: "transactionTotal",
	訂單原價: "originTransactionTotal",
	快遞費: "deliveryFee",
	優惠券碼: "couponCode",
	備註: "remarks",
	創建時間: "createTime",
	支付時間: "updateTime",
};

function SalesReceipt(): JSX.Element {
	const navigate = useNavigate();

	const [data, setData] = useState([]);
	const [payload, setPayload] = useState({
		pageSize: 50,
		pageNo: 1,
		statusFlag: "",
		payNetwork: "",
		startTime: "",
		endTime: "",
	});
	const [dateRange, setDateRange] = useState(['', '']);
	const [total, setTotal] = useState(0);
	const [isLoading, setIsLoading] = useState(false);

	const dataTableData: any = {
		columns: [
			{
				Header: "id",
				accessor: "id",
				Cell: ({ value }: any) => <IdCell id={value} />,
			},
			{
				Header: "Creation",
				accessor: "createTime",
				Cell: ({ value }: any) => <DefaultCell value={value} />,
			},
			{
				Header: "status flag",
				accessor: "statusFlag",
				Cell: ({ value }: any) => <DefaultCell value={value} />,
			},
			{
				Header: "Completion",
				accessor: "updateTime",
				Cell: ({ value }: any) => <DefaultCell value={value} />,
			},
			{
				Header: "transactionId",
				accessor: "transactionId",
				Cell: ({ value }: any) => <DefaultCell value={value} />,
			},
			{
				Header: "name",
				accessor: "name",
				Cell: ({ value }: any) => <DefaultCell value={value} />,
			},
			{
				Header: "email",
				accessor: "email",
				Cell: ({ value }: any) => <DefaultCell value={value} />,
			},
			{
				Header: "phone",
				accessor: "phone",
				Cell: ({ value }: any) => <DefaultCell value={value} />,
			},
			{
				Header: "actions",
				Cell: ({ row }: any) => (
					<ActionCell id={row.original.id} view={() => viewReceiptDetail(row.original.id)} />
				),
			},
		],

		rows: data,
	};

	const viewReceiptDetail = (id: any) => {
		navigate(`/transactions/individual-receipt/${id}`);
	};

	const handleChange = (event: any) => {
		const { name, value } = event.target;

		setPayload((prevState) => ({ ...prevState, [name]: value }));
	};

	const handleStatusClear = () => {
		setPayload((prevState) => ({ ...prevState, statusFlag: "" }));
	};

	const handlePaymentClear = () => {
		setPayload((prevState) => ({ ...prevState, payNetwork: "" }));
	};

	const queryAllReceipts = async (input: any) => {
		try {
			const result = await Axios.post(`${url}/rapidcart/admin/receipt/queryAll`, {
				...input,
			});
			if (result.data) {
				let dataList = result?.data?.data?.lists;
				const total = result?.data?.data.total || 0;
				setTotal(total);
				for (let i = 0; i < dataList.length; i++) {
					const element = dataList[i];

					if (element.createTime) {
						const date = new Date(element.createTime);
						const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
							2,
							"0"
						)}-${String(date.getDate()).padStart(2, "0")} @ ${String(date.getHours()).padStart(
							2,
							"0"
						)}:${String(date.getMinutes()).padStart(2, "0")}`;
						element.createTime = formattedDate;
					}

					if (element.statusFlag === "Successful") {
						if (element.updateTime) {
							const date = new Date(element.updateTime);
							const formattedDate = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
								2,
								"0"
							)}-${String(date.getDate()).padStart(2, "0")} @ ${String(date.getHours()).padStart(
								2,
								"0"
							)}:${String(date.getMinutes()).padStart(2, "0")}`;
							element.updateTime = formattedDate;
						}
					} else {
						if (element.updateTime) {
							const formattedDate = "-";
							element.updateTime = formattedDate;
						}
					}
				}

				setData(dataList);
			}
		} catch (err) {
			console.log("Get All Receipt Error:", err);
		}
	};

	const onDateRangePickerChange = (e: any) => {
		if (!e) {
			setDateRange(['', '']);
		} else {
			setDateRange([e[0].format('YYYY-MM-DD HH:mm:ss'), e[1].format('YYYY-MM-DD HH:mm:ss')]);
		}
	}

	const onSearchByDateRange = () => {
		const [startTime, endTime] = dateRange;
		setPayload((prevState) => ({ ...prevState, startTime, endTime }));
	}

	const onExportButtonClick = async () => {
		const [startTime, endTime] = dateRange;
		setIsLoading(true);
		const result = await Axios.post(`${url}/rapidcart/admin/receipt/queryAll`, { ...payload, pageSize: 100000, startTime, endTime });
		setIsLoading(false);
		const lists = result?.data?.data?.lists;
		if (Array.isArray(lists) && lists.length > 0) {
			const tableColumn = lists.map((n) => {
				return Object.values(ExportTableConfig).map(key => n[key]);
			});
			exportExcel(
				[Object.keys(ExportTableConfig), ...tableColumn],
				"Sales-Receipt.xlsx"
			);
		} else {
			message.error('The query failed or the data is empty');
		}
	}

	const onPageNumberChange = (pageNo: number) => {
		const newPayload = { ...payload, pageNo: pageNo + 1 }
		setPayload(newPayload);
	}

	useEffect(() => {
		queryAllReceipts(payload);
	}, [payload]);

	return (
		<DashboardLayout>
			<DashboardNavbar />

			<MDBox pt={6} pb={3}>
				<MDBox mb={3}>
					<Card>
						<MDBox p={3} lineHeight={1}>
							<MDTypography variant="h5" fontWeight="medium">
								Sales Receipt
							</MDTypography>

							<MDBox display="flex" justifyContent="space-between">
								<RadioGroup
									row
									name="statusFlag"
									value={payload.statusFlag || ""}
									onChange={handleChange}
								>
									<FormControlLabel value="Successful" control={<Radio />} label="Successful" />
									<FormControlLabel
										value="WaitingForPayment"
										control={<Radio />}
										label="Waiting for Payment"
									/>
								</RadioGroup>

								<Button onClick={handleStatusClear}>Clear Status</Button>
							</MDBox>

							<MDBox display="flex" justifyContent="space-between">
								<RadioGroup
									row
									name="payNetwork"
									value={payload.payNetwork || ""}
									onChange={handleChange}
								>
									<FormControlLabel value="信用卡" control={<Radio />} label="信用卡" />
									<FormControlLabel value="Fps" control={<Radio />} label="FPS" />
									<FormControlLabel value="PayMe" control={<Radio />} label="PayMe" />
									<FormControlLabel value="Alipay" control={<Radio />} label="Alipay" />
									<FormControlLabel value="Wechat" control={<Radio />} label="WeChat" />
								</RadioGroup>

								<Button onClick={handlePaymentClear}>Clear Payment</Button>
							</MDBox>
							<MDBox display="flex">
								<DatePicker.RangePicker allowClear={true} showTime onChange={onDateRangePickerChange} />
								<Button onClick={onSearchByDateRange}>Search By Date Range</Button>
							</MDBox>
							<MDBox display="flex" style={{ marginTop: '12px' }}>
								<AntdButton onClick={onExportButtonClick} style={{ color: '#fff' }} disabled={isLoading} loading={isLoading} type="primary">Export To Excel</AntdButton>
							</MDBox>
						</MDBox>
						<DataTable table={dataTableData} total={total} onPageNumberChange={onPageNumberChange} customPageNo={payload.pageNo} canSearch />
					</Card>
				</MDBox>
			</MDBox>

			<Footer />
		</DashboardLayout>
	);
}

export default SalesReceipt;
