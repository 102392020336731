import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

// EditProduct page components
import PictureProof from "./components/PictureProof";
import ProductInfo from "./components/SalesInfo";
import ProductCard from "./components/ProductCard";

//API
import Axios from "axios";
import { url } from "../../../utils/constant";

function IndividualReceipt(): JSX.Element {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    id: 0,
    statusFlag: "",
    name: "",
    phone: "",
    email: "",
    address: "",
    paymentMethod: "",
    payNetwork: "",
    transactionId: "",
    transactionTotal: "",
    createTime: "",
    updateTime: "",
  });

  const [productList, setProductList] = useState([]);

  //image source clone to display
  const [imageFiles, setImageFiles] = useState([]);

  let id = useParams();

  const queryOneReceipt = async () => {
    try {
      const result = await Axios.post(`${url}/rapidcart/admin/receipt/queryAll`, id);
      // console.log("Get One Receipt Res:", result);
      if (result.data.data) {
        let data = result.data.data.lists[0];
        let pictureProof = data.pictureProofs;
        let formatCreateTime: any;
        let formatUpdateTime: any;

        if (data.createTime) {
          const date = new Date(data.createTime);
          formatCreateTime = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
            2,
            "0"
          )}-${String(date.getDate()).padStart(2, "0")} @ ${String(date.getHours()).padStart(
            2,
            "0"
          )}:${String(date.getMinutes()).padStart(2, "0")}`;
        }

        if (data.statusFlag === "Successful") {
          if (data.updateTime) {
            const date = new Date(data.updateTime);
            formatUpdateTime = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(
              2,
              "0"
            )}-${String(date.getDate()).padStart(2, "0")} @ ${String(date.getHours()).padStart(
              2,
              "0"
            )}:${String(date.getMinutes()).padStart(2, "0")}`;
          }
        } else {
          if (data.updateTime) {
            formatUpdateTime = "-";
          }
        }

        setValues((prevState) => ({
          ...prevState,
          id: data.id,
          name: data.name,
          phone: data.phone,
          email: data.email,
          address: data.address,
          paymentMethod: data.paymentMethod,
          payNetwork: data.payNetwork,
          transactionId: data.transactionId,
          transactionTotal: data.transactionTotal,
          statusFlag: data.statusFlag,
          createTime: formatCreateTime,
          updateTime: formatUpdateTime,
        }));

        setProductList(data.skuList);

        if (pictureProof.length > 0) {
          setImageFiles(pictureProof);
        }
      }
    } catch (err) {
      console.log("Get One Receipt Error:", err);
    }
  };

  const backpage = () => {
    navigate("/transactions/sales-receipt");
  };

  useEffect(() => {
    queryOneReceipt();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                {`Sales Receipt for Transaction ID: ${values.transactionId}`}
              </MDTypography>
              <MDBox mt={1} mb={2}>
                <MDTypography variant="body2" color="text">
                  This is view only
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton variant="outlined" color="info" onClick={backpage}>
                  back
                </MDButton>
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          {imageFiles.length > 0 && (
            <Grid item xs={12} lg={12}>
              <PictureProof imageFiles={imageFiles} />
            </Grid>
          )}

          <Grid item xs={12} lg={12}>
            <ProductInfo values={values} />
          </Grid>

          {productList.map((item: any) => {
            return (
              <Grid item xs={12} lg={12}>
                <ProductCard
                  skuName={item.skuName}
                  skuSalePrice={item.skuSalePrice}
                  buyCount={item.buyCount}
                />
              </Grid>
            );
          })}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default IndividualReceipt;
