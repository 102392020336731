import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import DataTable from "./components/DataTable";
import IdCell from "./components/idCell";
import DefaultCell from "./components/DefaultCell";
import ActionCell from "./components/ActionCell";

//API
import Axios from "axios";
import { url } from "../../../utils/constant";

function GetAllInventory(): JSX.Element {
  const navigate = useNavigate();

  const [data, setData] = useState([]);

  const deleteProduct = async (id: any) => {
    try {
      const result = await Axios.post(`${url}/rapidcart/admin/inventory/delete`, { id: id });

      console.log("Delete Product Res:", result);
      let changeData = [...data];
      let matchIndex = changeData.map((item) => item.id).indexOf(id);

      changeData.splice(matchIndex, 1);
      setData(changeData);
    } catch (err) {
      console.log("Delete Product Error:", err);
    }
  };

  const handleEdit = (id: any) => {
    console.log("Edit:", id);
    navigate(`/operations/update-product/${id}`);
  };

  const handleRemove = (id: any) => {
    console.log("Remove:", id);
    deleteProduct(id);
  };

  const dataTableData: any = {
    columns: [
      {
        Header: "id",
        accessor: "id",
        Cell: ({ value }: any) => <IdCell id={value} />,
      },
      {
        Header: "title",
        accessor: "title",
        Cell: ({ value }: any) => <DefaultCell value={value} />,
      },
      {
        Header: "qty",
        accessor: "quantity",
        // Cell: ({ value }: any) => <DefaultCell value={value} />,
        Cell: ({ row }: any) => <DefaultCell value={row.original?.skuList?.[0]?.stock || 0} />,
      },
      {
        Header: "price",
        accessor: "price",
        // Cell: ({ value }: any) => <DefaultCell value={value} />,
        Cell: ({ row }: any) => <DefaultCell value={row.original?.skuList?.[0]?.salePrice || 0} />,
      },
      {
        Header: "on sale",
        accessor: "discountFlag",
        Cell: ({ value }: any) => <DefaultCell value={value.toString()} />,
      },
      {
        Header: "actions",
        Cell: ({ row }: any) => (
          <ActionCell
            id={row.original.id}
            edit={() => handleEdit(row.original.id)}
            remove={() => handleRemove(row.original.id)}
          />
        ),
      },
    ],

    rows: data,
  };

  const queryAllInventory = async () => {
    try {
      const result = await Axios.post(`${url}/rapidcart/admin/inventory/queryAll`, {
        pageSize: 10000,
      });

      let data = result.data.data.lists;
      console.log("Get All Res:", data);

      setData(data);
    } catch (err) {
      console.log("Get All Error:", err);
    }
  };

  useEffect(() => {
    queryAllInventory();
  }, []);

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox pt={6} pb={3}>
        <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Current Inventory
              </MDTypography>

              {/* <MDTypography variant="button" color="text">
                A lightweight, extendable, dependency-free javascript HTML table plugin.
              </MDTypography> */}
            </MDBox>
            <DataTable table={dataTableData} canSearch />
          </Card>
        </MDBox>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default GetAllInventory;
