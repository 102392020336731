import { useEffect, useState } from "react";
import { useNavigate, useParams } from 'react-router-dom';

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";

//components
import ApplicantInfo from "./components/ApplicantInfo";

//API
import Axios from "axios";
import { url } from "../../../utils/constant";

function UpdateProduct(): JSX.Element {

  const navigate = useNavigate();

  const [values, setValues] = useState({
    id: 0,
    companyName: "",
    host: "",
    email: "",
    rejectReason: "",
    auditStatus: "",
  });

  const [successAccept, setSuccessAccept] = useState<boolean>(false);
  const [successReject, setSuccessReject] = useState<boolean>(false);

  let id = useParams();

  const queryOneInventory = async () => {
    try {
      const result = await Axios.post(`${url}/rapidcart/admin/apply/queryAll`, id);

      let data = result.data.data.lists[0];
      console.log('Get One Applicant Res:', data);

      setValues((prevState) => ({ 
        ...prevState, 
        id: data.id,
        companyName: data.companyName,
        host: data.host,
        email: data.email,
        auditStatus: data.auditStatus,
      }));
      
    } catch (err) {
      console.log('Get One Applicant Error:', err);
    }
  };

  const openSuccessAccept = () => setSuccessAccept(true);
  const closeSuccessAccept = () => setSuccessAccept(false);

  const openSuccessReject = () => setSuccessReject(true);
  const closeSuccessReject = () => setSuccessReject(false);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const backPage = () => {
    navigate('/super-admin/applications')
  };

  const accept = async () => {
    try {
      let data = {
        id: values.id,
      };

      const result = await Axios.post(`${url}/rapidcart/admin/apply/pass`, data);
      console.log("accept res:", result);
      openSuccessAccept();
    } catch (err) {
      console.log('accept Error:', err);
    }
  };

  const reject = async () => {
    try {
      let data = {
        id: values.id,
        rejectReason: values.rejectReason,
      };

      const result = await Axios.post(`${url}/rapidcart/admin/apply/refuse`, data);
      console.log("reject res:", result);
      openSuccessReject();
    } catch (err) {
      console.log('reject Error:', err);
    }
  };

  const handleAccept = async () => {
    accept();
  };

  const handleReject = async () => {
    reject();
  };

  useEffect(() => {
    queryOneInventory();
  }, []);

  const renderSuccessAccept = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Applicant Approved"
      content="The applicant has been accepted!"
      dateTime=""
      open={successAccept}
      onClose={closeSuccessAccept}
      close={closeSuccessAccept}
      bgWhite
    />
  );

  const renderSuccessReject = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Applicant Rejected"
      content="The applicant has been denied!"
      dateTime=""
      open={successReject}
      onClose={closeSuccessReject}
      close={closeSuccessReject}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />

      <MDBox my={3}>
        <MDBox mb={6}>
          <Grid container spacing={3} alignItems="center">
            <Grid item xs={12} lg={6}>
              <MDTypography variant="h4" fontWeight="medium">
                Applicant Review
              </MDTypography>
              <MDBox mt={1} mb={2}>
                <MDTypography variant="body2" color="text">
                  Approve the applicant by clicking the accept button. A reason must be provided before 'Rejecting' an applicant.
                </MDTypography>
              </MDBox>
            </Grid>
            <Grid item xs={12} lg={6}>
              <MDBox display="flex" justifyContent="flex-end">
                <MDButton 
                  variant="gradient" 
                  color="info" 
                  onClick={backPage}
                  style={{ marginRight: "5px" }}
                >
                  Back
                </MDButton>

                {
                  values.auditStatus !== "APPROVED" && (
                    <>
                      <MDButton 
                        variant="gradient" 
                        color="error" 
                        onClick={handleReject}
                        style={{ marginRight: "5px" }}
                      >
                        Reject
                      </MDButton>

                      <MDButton 
                        variant="gradient" 
                        color="warning" 
                        onClick={handleAccept}
                      >
                        Accept
                      </MDButton>
                    </>
                  )
                }
                
                {renderSuccessAccept}
                {renderSuccessReject}
              </MDBox>
            </Grid>
          </Grid>
        </MDBox>
        <Grid container spacing={3}>
          {/* {
            imageFiles.map((item, index) => (
              <Grid item xs={12} lg={4}>
                <ProductImage 
                  imageUrl={item.url}
                  handleEdit={(e) => handleEdit(e, index)}
                  key={index}
                />
              </Grid>
            ))
          } */}
          <Grid item xs={12} lg={12}>
            <ApplicantInfo values={values} inputUpdates={handleChange}/>
          </Grid>
          {/* <Grid item xs={12} lg={12}>
            <Pricing values={values} inputUpdates={handleChange}/>
          </Grid> */}
        </Grid>
      </MDBox>
      <Footer />
    </DashboardLayout>
  );
}

export default UpdateProduct;