import { useEffect } from "react";

// @mui material components
import Grid from "@mui/material/Grid";

import { useState } from "react";
// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDSnackbar from "components/MDSnackbar";

// Settings page components
import BaseLayout from "layouts/pages/account/components/BaseLayout";
import Sidenav from "layouts/pages/account/settings/components/Sidenav";
// import Header from "layouts/pages/account/settings/components/Header";
import BasicInfo from "layouts/pages/account/settings/components/BasicInfo";
import WebConfig from "./components/WebConfig";
import ChangePassword from "layouts/pages/account/settings/components/ChangePassword";
// import Authentication from "layouts/pages/account/settings/components/Authentication";
// import Accounts from "layouts/pages/account/settings/components/Accounts";
// import Notifications from "layouts/pages/account/settings/components/Notifications";
// import Sessions from "layouts/pages/account/settings/components/Sessions";
// import DeleteAccount from "layouts/pages/account/settings/components/DeleteAccount";

//API
import Axios from "axios";
import { url } from "../../../../utils/constant";

function Settings(): JSX.Element {
  const [credentials, setCredentials] = useState({
    newPassword: "",
    captcha: "",
  });

  const [webConfig, setWebConfig] = useState({
    logo: "",
    name: "",
    email: "",
    phone: "",
    homePageUrl: "",
    showNavItems: false,
  });

  //image source clone to display
  // const [imageFiles, setImageFiles] = useState([]);
  const [fileName, setFileName] = useState("");
  const [sendFiles, setSendFiles] = useState([]);

  // const [faviconName, setFaviconName] = useState("");
  // const [sendFaviconFile, setSendFaviconFile] = useState([]);

  const [successPW, setSuccessPW] = useState<boolean>(false);
  const [successConfig, setSuccessConfig] = useState<boolean>(false);

  const openSuccessPW = () => setSuccessPW(true);
  const closeSuccessPW = () => setSuccessPW(false);

  const openSuccessConfig = () => setSuccessConfig(true);
  const closeSuccessConfig = () => setSuccessConfig(false);

  const handleCredentials = (e: any) => {
    const { name, value } = e.target;
    setCredentials((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleWebConfig = (e: any) => {
    const { name, value } = e.target;
    setWebConfig((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleWebConfigSwitch = (e: React.ChangeEvent<HTMLInputElement>) => {
    setWebConfig((prevState) => ({
      ...prevState,
      showNavItems: e.target.checked,
    }));
  };

  const handleEdit = (e: any) => {
    console.log("Event", e.target.files[0]);

    let fileArray: any = [];
    let fileObj = e.target.files[0];
    let name = e.target.files[0].name;

    if (e.target.files) {
      fileArray = Array.from(e.target.files).map((file: any) => URL.createObjectURL(file));

      // let tempImageArray = [...imageFiles];
      // tempImageArray[0].url = fileArray[0];

      // setImageFiles(tempImageArray);
      setFileName(name);
      sendFiles.push(fileObj);
    }
  };

  // const handleFavicon = (e: any) => {
  //   console.log('Favicon Event', e.target.files[0]);

  //   let fileArray: any = [];
  //   let fileObj = e.target.files[0];
  //   let name = e.target.files[0].name;

  //   if (e.target.files) {
  //     fileArray = Array.from(e.target.files).map((file: any) => URL.createObjectURL(file));

  //     setFaviconName(name);
  //     sendFaviconFile.push(fileObj);
  //   }
  // };

  const packageUploadData = (file: any) => {
    const formData = new FormData();
    formData.append("file", file); //the actual file to upload
    console.log("formData", formData);
    return formData;
  };

  const uploadImage = async (file: any, pictureUrl: any) => {
    try {
      const data = packageUploadData(file);

      const result = await Axios.post(`${url}/rapidcart/admin/oss/uploadImage`, data);

      console.log("Upload Result:", result);

      if (result.data.code === 200) {
        let url = result.data.data.url;
        pictureUrl.push(url);
      }
    } catch (err) {
      console.log("uploadImage Error:", err);
    }
  };

  const handleCaptcha = () => {
    try {
      const result = Axios.post(`${url}/rapidcart/admin/sendMailCode`);

      console.log("Get Captcha Result:", result);
    } catch (err) {
      console.log("Get Captcha Error:", err);
    }
  };

  const handlePasswordChange = () => {
    try {
      const result = Axios.post(`${url}/rapidcart/admin/changePassword`, credentials);

      console.log("Change Password Result:", result);

      openSuccessPW();
    } catch (err) {
      console.log("Change Password Error:", err);
    }
  };

  const webConfigUpdate = async (input: any, logoUrl: any, faviconUrl: any) => {
    try {
      let data = input;

      if (logoUrl.length > 0) {
        data.logo = logoUrl[0];
      }

      if (faviconUrl.length > 0) {
        data.favicon = faviconUrl[0];
      }

      console.log("Sending:", data);
      const result = await Axios.post(`${url}/rapidcart/admin/website/update`, data);

      console.log("WebConfig Result:", result);
      openSuccessConfig();
    } catch (err) {
      console.log("WebConfig Error:", err);
    }
  };

  const submitWebConfig = async () => {
    let logoUrl: any = [];
    let faviconUrl: any = [];

    if (sendFiles.length > 0) {
      for (const file of sendFiles) {
        await uploadImage(file, logoUrl);
      }
    }

    // if (sendFaviconFile.length > 0) {
    //   for (const file of sendFaviconFile) {
    //     await uploadImage(file, faviconUrl);
    //   }
    // }

    await webConfigUpdate(webConfig, logoUrl, faviconUrl);
  };

  const renderSuccessPW = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Password Updated"
      content="Your password has been successfully changed!"
      dateTime=""
      open={successPW}
      onClose={closeSuccessPW}
      close={closeSuccessPW}
      bgWhite
    />
  );

  const renderSuccessConfig = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Web Configuration Updated"
      content="Your web configuration has been successfully changed!"
      dateTime=""
      open={successConfig}
      onClose={closeSuccessConfig}
      close={closeSuccessConfig}
      bgWhite
    />
  );

  const queryWebDetail = async () => {
    try {
      const result = await Axios.post(`${url}/rapidcart/admin/website/detail`);

      let data = result.data.data;

      if (data !== null) {
        setWebConfig((prevState) => ({
          ...prevState,
          logo: data.logo,
          name: data.name,
          email: data.email,
          phone: data.phone,
          homePageUrl: data.homePageUrl ? data.homePageUrl : "",
          showNavItems: data.showNavItems,
        }));
      }

      console.log("WebDetail Result:", result);
    } catch (err) {
      console.log("WebDetail Error:", err);
    }
  };

  useEffect(() => {
    queryWebDetail();
  }, []);

  return (
    <BaseLayout>
      <MDBox mt={4}>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={3}>
            <Sidenav />
          </Grid>
          <Grid item xs={12} lg={9}>
            <MDBox mb={3}>
              <Grid container spacing={3}>
                {/* <Grid item xs={12}>
                  <Header />
                </Grid> */}
                <Grid item xs={12}>
                  <BasicInfo />
                </Grid>
                <Grid item xs={12}>
                  <WebConfig
                    webConfig={webConfig}
                    fileName={fileName}
                    // faviconName={faviconName}
                    inputUpdates={handleWebConfig}
                    handleEdit={(e) => handleEdit(e)}
                    handleSwitch={handleWebConfigSwitch}
                    // handleFavicon={(e) => handleFavicon(e)}
                    submit={submitWebConfig}
                  />
                  {renderSuccessConfig}
                </Grid>
                <Grid item xs={12}>
                  <ChangePassword
                    credentials={credentials}
                    inputUpdates={handleCredentials}
                    handleCaptcha={handleCaptcha}
                    handlePwChange={handlePasswordChange}
                  />
                  {renderSuccessPW}
                </Grid>
                {/* <Grid item xs={12}>
                  <Authentication />
                </Grid>
                <Grid item xs={12}>
                  <Accounts />
                </Grid>
                <Grid item xs={12}>
                  <Notifications />
                </Grid>
                <Grid item xs={12}>
                  <Sessions />
                </Grid> */}
                {/* <Grid item xs={12}>
                  <DeleteAccount />
                </Grid> */}
              </Grid>
            </MDBox>
          </Grid>
        </Grid>
      </MDBox>
    </BaseLayout>
  );
}

export default Settings;
