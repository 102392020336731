import { useState } from "react";
import { useNavigate } from 'react-router-dom';

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDButton from "components/MDButton";
import Grid from "@mui/material/Grid";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import MDSnackbar from "components/MDSnackbar";

// Material Dashboard 2 PRO React TS examples components
import DashboardLayout from "examples/LayoutContainers/DashboardLayout";
import DashboardNavbar from "examples/Navbars/DashboardNavbar";
import Footer from "examples/Footer";
import ProductInfo from "./components/ProductInfo";
import Pricing from "./components/Pricing";
import Media from "./components/Media";

//API
import Axios from "axios";
import { url } from "../../../utils/constant";

// @ts-ignore
import $ from "jquery";

function CreateProduct(): JSX.Element {
  const navigate = useNavigate();

  const [values, setValues] = useState({
    title: "",
    description: "",
    description2: "",
    description3: "",
    promotionDescription: "",
    salesPolicy: "",
    price: "",
    quantity: "",
    minQuantity: "",
    discountedUnitPrice: "",
    unitQuantityA: "",
    unitDiscountA: "",
    unitQuantityB: "",
    unitDiscountB: "",
    remarks: "",
    pictureUrls: [],
  });

  const [unitDiscountPromo, setUnitDiscountPromo] = useState([
    {
      type: "MIN_UNIT_DISCOUNT",
      unitQuantity: 0,
      unitDiscount: 0,
    },
  ]);

  const [groupPromo, setGroupPromo] = useState([
    {
      type: "OVER_AMOUNT_DISCOUNT",
      unitQuantity: 0,
      unitDiscount: 0,
    },
  ]);

  function getSteps(): string[] {
    return ["1. Product Info", "2. Pricing & Discount", "3. Upload Images"];
  }

  const [filesArray, setFilesArray] = useState([]);

  const [activeStep, setActiveStep] = useState<number>(0);
  const [successSB, setSuccessSB] = useState<boolean>(false);
  const [errorSB, setErrorSB] = useState<boolean>(false);

  const steps = getSteps();
  const isLastStep: boolean = activeStep === steps.length - 1;

  const handleNext = () => setActiveStep(activeStep + 1);
  const handleBack = () => setActiveStep(activeStep - 1);

  const openSuccessSB = () => setSuccessSB(true);
  const closeSuccessSB = () => setSuccessSB(false);

  const openErrorSB = () => setErrorSB(true);
  const closeErrorSB = () => setErrorSB(false);

  const handleGroupPromo = () => {
    setGroupPromo([...groupPromo, {
      type: "OVER_AMOUNT_DISCOUNT",
      unitQuantity: 0,
      unitDiscount: 0,
    }]);
  };

  const handleGroupPromoRemove = (index: any) => {
    console.log("Index:", index);
    const list = [...groupPromo];
    list.splice(index, 1);
    setGroupPromo(list);
  };

  const handleGroupPromoChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list = [...groupPromo] as any;
    list[index][name] = Number(value);
    setGroupPromo(list);
  };

  const handleUnitDiscountChange = (e: any, index: any) => {
    const { name, value } = e.target;
    const list = [...unitDiscountPromo] as any;
    list[index][name] = Number(value);
    setUnitDiscountPromo(list);
  };

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setValues((prevState) => ({ ...prevState, [name]: value }));
  };

  const handleFilesChange = (file: any) => {
    filesArray.push(file);
    console.log('what is filesArray:', filesArray);
  };

  const handleFilesRemoved = (file: any) => {
    const indexOfObject = filesArray.findIndex((obj: any) => {
      return obj.name === file.name
    }
    );

    filesArray.splice(indexOfObject, 1);
    console.log('after remove:', filesArray);
  };

  function getStepContent(stepIndex: number): JSX.Element {
    switch (stepIndex) {
      case 0:
        return <ProductInfo values={values} inputUpdates={handleChange} />;
      case 1:
        return <Pricing
          values={values}
          inputUpdates={handleChange}
          groupPromo={groupPromo}
          unitDiscountPromo={unitDiscountPromo}
          handleGroupPromo={handleGroupPromo}
          handleGroupPromoRemove={handleGroupPromoRemove}
          handleGroupPromoChange={handleGroupPromoChange}
          handleUnitDiscountChange={handleUnitDiscountChange}
        />;
      case 2:
        return <Media
          handleFilesChange={handleFilesChange}
          handleFilesRemoved={handleFilesRemoved}
        />;
      default:
        return null;
    }
  }

  const packageUploadData = (file: any) => {
    const formData = new FormData();
    formData.append('file', file); //the actual file to upload
    console.log('formData', formData);
    return formData;
  }

  const uploadImage = async (file: any, pictureArray: any) => {
    try {
      const data = packageUploadData(file);

      const result = await Axios.post(`${url}/rapidcart/admin/oss/uploadImage`, data);

      console.log('Uploade Result:', result);

      if (result.data.code === 200) {
        let url = result.data.data.url;
        pictureArray.push({ "url": url });
      }

    } catch (err) {
      console.log('uploadImage Error:', err);
    }
  };

  const removeDropZonePreview = () => {
    $(".dz-preview").remove();
  };

  const createProduct = async (product: any, pictureArray: any) => {
    try {
      let data = product;

      if (pictureArray.length > 0) {
        data.pictureUrls = pictureArray;
      }

      const result = await Axios.post(`${url}/rapidcart/admin/inventory/create`, data);
      console.log("createProduct res:", result);

      let requiredResponse = {} as any;

      if (result.data.code === 200) {
        requiredResponse.name = result.data.data.title;
        requiredResponse.inventoryId = result.data.data.id;
      }

      return requiredResponse;
    } catch (err) {
      console.log('createProduct Error:', err);
    }
  };

  const createSku = async (product: any, response: any) => {
    try {
      const {
        price,
        quantity,
      } = product;

      const { name, inventoryId } = response;

      const discountRulesArray: any = [];

      let data = {
        name,
        inventoryId,
        salePrice: parseFloat(price),
        stock: parseInt(quantity),
        discountRules: discountRulesArray,
      }

      for (let unitItems = 0; unitItems < unitDiscountPromo.length; unitItems++) {
        for (let groupItems = 0; groupItems < groupPromo.length; groupItems++) {

          if (unitDiscountPromo[unitItems].unitQuantity > 0
            && unitDiscountPromo[unitItems].unitDiscount > 0
            && groupPromo[groupItems].unitQuantity > 0
            && groupPromo[groupItems].unitDiscount > 0
          ) {
            data.discountRules = [...unitDiscountPromo, ...groupPromo];

          } else if (unitDiscountPromo[unitItems].unitQuantity > 0
            && unitDiscountPromo[unitItems].unitDiscount > 0
            && groupPromo[groupItems].unitQuantity === 0
            && groupPromo[groupItems].unitDiscount === 0
          ) {
            data.discountRules = [...unitDiscountPromo];

          } else if (unitDiscountPromo[unitItems].unitQuantity === 0
            && unitDiscountPromo[unitItems].unitDiscount === 0
            && groupPromo[groupItems].unitQuantity > 0
            && groupPromo[groupItems].unitDiscount > 0
          ) {
            data.discountRules = [...groupPromo];

          }
        }
      }

      console.log('createSku Data:', data);
      const result = await Axios.post(`${url}/rapidcart/admin/inventorySku/create`, data);

      console.log("createSKU res:", result);
      removeDropZonePreview();
      openSuccessSB();
    } catch (err) {
      console.log('createSKU Error:', err);
    }
  };

  const handleSubmit = async () => {
    console.log('submitting');
    console.log('whats my files:', filesArray);
    let pictureArray: any = [];

    if (values.title === ""
      || values.description === ""
      || values.price === ""
      || isNaN(+values.quantity) || Number(values.quantity) < 0
    ) {
      console.log('All mandatory fields must be completed');
      return openErrorSB();
    }

    if (filesArray.length > 0) {
      for (const file of filesArray) {
        await uploadImage(file, pictureArray);
      }
    }

    const productResponse = await createProduct(values, pictureArray);

    await createSku(values, productResponse);

    setTimeout(() => {
      navigate('/operations/get-all-inventory');
    }, 500);
  };

  const renderSuccessSB = (
    <MDSnackbar
      color="success"
      icon="check"
      title="Product Created"
      content="Your product has been successfully created!"
      dateTime=""
      open={successSB}
      onClose={closeSuccessSB}
      close={closeSuccessSB}
      bgWhite
    />
  );

  const renderErrorSB = (
    <MDSnackbar
      color="error"
      icon="warning"
      title="Product Creation Error"
      content="You must complete all mandetory fields!"
      dateTime=""
      open={errorSB}
      onClose={closeErrorSB}
      close={closeErrorSB}
      bgWhite
    />
  );

  return (
    <DashboardLayout>
      <DashboardNavbar />
      {/* <MDBox mb={3}>
          <Card>
            <MDBox p={3} lineHeight={1}>
              <MDTypography variant="h5" fontWeight="medium">
                Create Inventory
              </MDTypography>

              <MDTypography variant="button" color="text">
                By clicking the button below, we will retrieve all data from the client's Google Sheet.
              </MDTypography>
            </MDBox>
          </Card>
        </MDBox> */}

      <MDBox mt={5} mb={9}>
        <Grid container justifyContent="center">
          <Grid item xs={12} lg={8}>
            <MDBox mt={6} mb={8} textAlign="center">
              <MDBox mb={1}>
                <MDTypography variant="h3" fontWeight="bold">
                  Add New Product
                </MDTypography>
              </MDBox>
              <MDTypography variant="h5" fontWeight="regular" color="secondary">
                This information will describe more about the product.
              </MDTypography>
            </MDBox>
            <Card>
              <MDBox mt={-3} mb={3} mx={2}>
                <Stepper activeStep={activeStep} alternativeLabel>
                  {steps.map((label) => (
                    <Step key={label}>
                      <StepLabel>{label}</StepLabel>
                    </Step>
                  ))}
                </Stepper>
              </MDBox>
              <MDBox p={2}>
                <MDBox>
                  {getStepContent(activeStep)}
                  <MDBox mt={3} width="100%" display="flex" justifyContent="space-between">
                    {activeStep === 0 ? (
                      <MDBox />
                    ) : (
                      <MDButton variant="gradient" color="light" onClick={handleBack}>
                        back
                      </MDButton>
                    )}
                    <MDButton
                      variant="gradient"
                      color="dark"
                      onClick={
                        !isLastStep
                          ?
                          handleNext
                          :
                          handleSubmit
                      }
                    >
                      {isLastStep ? "send" : "next"}
                    </MDButton>

                    {renderSuccessSB}
                    {renderErrorSB}
                  </MDBox>
                </MDBox>
              </MDBox>
            </Card>
          </Grid>
        </Grid>
      </MDBox>

      <Footer />
    </DashboardLayout>
  );
}

export default CreateProduct;