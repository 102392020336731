// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import Autocomplete from "@mui/material/Autocomplete";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
// import MDInput from "components/MDInput";
import MDButton from "components/MDButton";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";

interface Props {
  promoObjects: { [key: string]: any };
  percentDiscount: { [key: string]: any };
  skuId: number;
  handleCardRemove: (event: any) => void;
  inputUpdates: (event: any, index: any) => void;
  inputPercentDiscount: (event: any) => void;
  addNestedRules: (index: any) => void;
  removeNestedRules: (event: any) => void;
  handleRemoveSku: (event: any) => void;
  handleAddSetDiscount: (index: any) => void;
  handleAddUnitDiscount: (index: any) => void;
}

function DynamicPriceCard({
  promoObjects,
  percentDiscount,
  skuId,
  handleCardRemove,
  inputUpdates,
  inputPercentDiscount,
  addNestedRules,
  removeNestedRules,
  handleRemoveSku,
  handleAddSetDiscount,
  handleAddUnitDiscount,
}: Props): JSX.Element {
  const { name, salePrice, stock, discountRules } = promoObjects;

  let unitPromo: any = [];
  let groupPromo: any = [];

  // if (discountRules !== null) {
  if (Array.isArray(discountRules) && discountRules.length > 0) {
    for (let rule = 0; rule < discountRules.length; rule++) {
      if (discountRules[rule].type === "MIN_UNIT_DISCOUNT") {
        unitPromo.push(discountRules[rule]);
      } else if (discountRules[rule].type === "OVER_AMOUNT_DISCOUNT") {
        groupPromo.push(discountRules[rule]);
      }
    }
  }
  // } else {
  //   unitPromo.push({ type: "MIN_UNIT_DISCOUNT" });
  //   groupPromo.push({ type: "OVER_AMOUNT_DISCOUNT" });
  // }

  return (
    <Card sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        {skuId ? (
          <>
            <MDTypography variant="h5">Current Price & Promotions</MDTypography>
            <MDTypography variant="h6" mt={1}>
              The first card will always be the default price and quantity of the product.
            </MDTypography>
            <MDTypography variant="h6" mt={1}>
              'Delete Promotion' will remove the price, quantity and promotions set on the card
              completely
            </MDTypography>
          </>
        ) : (
          <>
            <MDTypography variant="h5">New Price & Promotions</MDTypography>
            <MDTypography variant="h6" mt={1}>
              The first card will always be the default price and quantity of the product.
            </MDTypography>
            <MDTypography variant="h6" mt={1}>
              If you have multiple variant of the same product, press 'Add more cards' to set a mix
              & match promotion
            </MDTypography>
          </>
        )}

        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <FormField
                name="name"
                value={name}
                type="text"
                label="Product Name"
                placeholder="Product Name"
                onChange={inputUpdates}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <FormField
                name="salePrice"
                value={salePrice}
                type="text"
                label="Price"
                placeholder="0.00"
                onChange={inputUpdates}
              />
            </Grid>

            <Grid item xs={12} sm={5}>
              <FormField
                name="stock"
                value={stock}
                type="text"
                label="Quantity"
                placeholder="0.00"
                onChange={inputUpdates}
              />
            </Grid>
          </Grid>
        </MDBox>

        <MDTypography variant="h5" mt={3}>
          Discounts (Optional)
        </MDTypography>

        {unitPromo.length > 0 && (
          <>
            <MDTypography variant="h6" mt={3}>
              Per Unit Discount(s)
            </MDTypography>
            <MDTypography variant="h6" mt={1}>
              Original price will be discounted to the set 'unit price' if quantity added to cart is
              equivalent or greater
            </MDTypography>
          </>
        )}

        <MDBox mt={3}>
          {unitPromo.map((item: any, index: any) => (
            <>
              {item.type === "MIN_UNIT_DISCOUNT" && (
                <>
                  <Grid container spacing={3}>
                    <Grid item xs={12} sm={5}>
                      <FormField
                        id={`MIN_UNIT_DISCOUNT-${index}`}
                        type="text"
                        label="Unit Quantity"
                        placeholder="0.00"
                        name="unitQuantity"
                        value={item.unitQuantity}
                        onChange={inputUpdates}
                      />
                    </Grid>

                    <Grid item xs={12} sm={5}>
                      <FormField
                        id={`MIN_UNIT_DISCOUNT-${index}`}
                        type="text"
                        label="Unit Price"
                        placeholder="0.00"
                        name="unitDiscount"
                        value={item.unitDiscount}
                        onChange={inputUpdates}
                      />
                    </Grid>
                  </Grid>
                </>
              )}
            </>
          ))}

          <MDBox mt={3}>
            {groupPromo.length > 0 && (
              <>
                <MDTypography variant="h6" mt={3}>
                  Per Set Discount(s)
                </MDTypography>
                <MDTypography variant="h6" mt={1}>
                  Original price will be discounted to the 'set price' if quantity added to cart is
                  equivalent to the 'set quantity'
                </MDTypography>
                <MDTypography variant="h6" mt={1}>
                  If multiple sets are added, we will calculate the best offer to your customers
                </MDTypography>
              </>
            )}

            {groupPromo.map((item: any, index: any) => (
              <>
                <Grid container spacing={3} key={index}>
                  <Grid item xs={12} sm={5}>
                    <FormField
                      id={`OVER_AMOUNT_DISCOUNT-${index}`}
                      a-key={index}
                      type="text"
                      label="Set Quantity"
                      placeholder="0.00"
                      name="unitQuantity"
                      value={item.unitQuantity}
                      onChange={inputUpdates}
                    />
                  </Grid>

                  <Grid item xs={12} sm={5}>
                    <FormField
                      id={`OVER_AMOUNT_DISCOUNT-${index}`}
                      a-key={index}
                      type="text"
                      label="Set Price"
                      placeholder="0.00"
                      name="unitDiscount"
                      value={item.unitDiscount}
                      onChange={inputUpdates}
                    />
                  </Grid>

                  {groupPromo.length > 1 && (
                    <MDButton
                      id={index}
                      variant="gradient"
                      color="dark"
                      size="small"
                      style={{ marginLeft: "10px", marginTop: "30px", height: "30px" }}
                      onClick={removeNestedRules}
                    >
                      Remove
                    </MDButton>
                  )}
                </Grid>

                {groupPromo.length - 1 === index && groupPromo.length < 4 && (
                  <MDBox
                    display="flex"
                    justifyContent="space-between"
                    alignItems="flex-start"
                    flexWrap="wrap"
                    style={{ marginTop: "10px" }}
                  >
                    <MDButton variant="gradient" color="dark" size="small" onClick={addNestedRules}>
                      Add More Set Discounts
                    </MDButton>
                  </MDBox>
                )}
              </>
            ))}
          </MDBox>

          <MDBox mt={3}>
            <MDTypography variant="h6" mt={3}>
              Percentage Discount by Amount
            </MDTypography>
            <MDTypography variant="h6" mt={1}>
              To be discounted when a set amount is reached.
            </MDTypography>

            <MDBox mt={3}>
              <Grid container spacing={3}>
                <Grid item xs={12} sm={5}>
                  <FormField
                    id={`OVER_AMOUNT_PERCENT_DISCOUNT`}
                    type="text"
                    label="Amount Threshold"
                    placeholder="0.00"
                    name="unitQuantity"
                    value={percentDiscount.unitQuantity}
                    onChange={inputPercentDiscount}
                    disabled
                  />
                </Grid>

                <Grid item xs={12} sm={5}>
                  <FormField
                    id={`OVER_AMOUNT_PERCENT_DISCOUNT`}
                    type="text"
                    label="Paid Percentage (eg: if 10% off, enter 90)"
                    placeholder="0.00"
                    name="unitDiscount"
                    value={percentDiscount.unitDiscount}
                    onChange={inputPercentDiscount}
                    disabled
                  />
                </Grid>
              </Grid>
            </MDBox>
          </MDBox>
        </MDBox>
      </MDBox>

      {!skuId && (
        <MDBox p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <MDButton variant="gradient" color="dark" size="small" onClick={handleCardRemove}>
                Remove Card
              </MDButton>
            </Grid>
          </Grid>
        </MDBox>
      )}

      {skuId && (
        <MDBox p={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <MDButton
                id={skuId.toString()}
                variant="gradient"
                color="error"
                size="small"
                onClick={handleRemoveSku}
              >
                Delete Promotion
              </MDButton>
            </Grid>

            {unitPromo.length < 1 && (
              <Grid item xs={12} sm={5}>
                <MDButton
                  id={skuId.toString()}
                  variant="gradient"
                  color="dark"
                  size="small"
                  onClick={handleAddUnitDiscount}
                >
                  Add Unit Discount
                </MDButton>
              </Grid>
            )}

            {groupPromo.length < 1 && (
              <Grid item xs={12} sm={5}>
                <MDButton
                  id={skuId.toString()}
                  variant="gradient"
                  color="dark"
                  size="small"
                  onClick={handleAddSetDiscount}
                >
                  Add Set Discounts
                </MDButton>
              </Grid>
            )}
          </Grid>
        </MDBox>
      )}
    </Card>
  );
}

DynamicPriceCard.defaultProps = {
  promoObjects: {
    name: "",
    inventoryId: "",
    salePrice: "",
    stock: "",
    discountRules: [
      {
        type: "MIN_UNIT_DISCOUNT",
        unitQuantity: 0,
        unitDiscount: 0,
      },
      {
        type: "OVER_AMOUNT_DISCOUNT",
        unitQuantity: 0,
        unitDiscount: 0,
      },
    ],
  },
};

export default DynamicPriceCard;
