// @mui material components
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";
import MDInput from "components/MDInput";

// NewProduct page components
import FormField from "layouts/ecommerce/products/edit-product/components/FormField";

interface Props {
  values: { [key: string]: any };
  inputUpdates: (event: any) => void;
}

function ApplicantInfo({ values, inputUpdates  }: Props): JSX.Element {
  const { companyName, host, email, rejectReason, auditStatus } = values;

  return (
    <Card>
      <MDBox p={3}>
      <MDTypography variant="h5">Applicant Information</MDTypography>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField 
              name="companyName"
              value={companyName} 
              type="text" 
              label="Company Name" 
              disabled
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField 
              name="host"
              value={host} 
              type="text" 
              label="Host Name" 
              disabled
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField 
              name="email"
              value={email} 
              type="text" 
              label="Email Address" 
              disabled
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <FormField 
              name="auditStatus"
              value={auditStatus} 
              type="text" 
              label="Audit Status" 
              disabled
            />
          </Grid>
        </Grid>
      </MDBox>
      <MDBox mt={3}>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={12}>
            <MDBox mb={1} ml={0.5} lineHeight={0} display="flex">
              <MDTypography component="label" variant="button" fontWeight="regular" color="text">
                Reject Reason
              </MDTypography>
            </MDBox>
            <MDInput 
              name="rejectReason"
              value={rejectReason} 
              onChange={inputUpdates} 
              multiline 
              rows={5}
              fullWidth
            />
          </Grid>
        </Grid>
      </MDBox>
    </MDBox>
    </Card>
  );
}

ApplicantInfo.defaultProps = {
  values: {
    companyName: "",
    host: "",
    email: "",
    rejectReason: "",
    auditStatus: "",
  }
};

export default ApplicantInfo;