// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDTypography from "components/MDTypography";

// NewProduct page components
import FormField from "layouts/ecommerce/products/new-product/components/FormField";

interface Props {
  sourceId: string | number;
  destinationId: string | number;
  inputUpdates: (event: any) => void;
}

function ProductPosition({ 
  sourceId,
  destinationId,
  inputUpdates,
}: Props): JSX.Element {

  return (
    <Card sx={{ overflow: "visible" }}>
      <MDBox p={3}>
        <MDTypography variant="h5">Change Product Position (Optional)</MDTypography>
        <MDTypography variant="h6">**Position of 'source ID' will always be updated after 'destination ID'**</MDTypography>
        <MDTypography variant="h6">**If you want the current product to be placed first, set 'destination ID' as the current first 'product ID'**</MDTypography>
        
        <MDBox mt={3}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={5}>
              <FormField 
                name="sourceId"
                value={sourceId} 
                type="text" 
                label="SourceID" 
                placeholder="0" 
                disabled
              />
            </Grid>

            <Grid item xs={12} sm={5}>
            <FormField 
                name="destinationId"
                value={destinationId} 
                type="text" 
                label="Destination ID" 
                placeholder="0" 
                onChange={inputUpdates}
              />
            </Grid>
          </Grid>
        </MDBox>
      </MDBox>
    </Card>
  );
}

ProductPosition.defaultProps = {
};

export default ProductPosition;