import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Snackbar, { SnackbarOrigin } from '@mui/material/Snackbar';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

//API
import Axios from "axios";

//utils
import { url } from "../utils/constant";
import { setSession } from "../utils/jwt";

interface AuthInterface {
  auth: boolean;
  setAuth: (boolean: boolean) => void;
  accountName: string;
  setAccountName: (string: string) => void;
  companyName: string;
  setCompanyName: (string: string) => void;
  host: string;
  setHost: (string: string) => void;
  role: string;
  setRole: (string: string) => void;
  signIn: (data: object) => void;
  signOut: () => void;
  register: (data: object) => void;
  createApplication: (data: object) => void;
}

const defaultState = {
  auth: false,
  setAuth: () => { },
  accountName: "",
  companyName: "",
  setCompanyName: () => { },
  host: "",
  setHost: () => { },
  setAccountName: () => { },
  role: "",
  setRole: () => { },
  signIn: () => { },
  signOut: () => { },
  register: () => { },
  createApplication: () => { },
}

const AuthContext = React.createContext<AuthInterface>(defaultState);

const AuthProvider: React.FC = ({ children }) => {
  const navigate = useNavigate();
  const [state, setState] = useState({
    open: false,
    backdropOpen: false,
    signErrorMessage: '',
  });

  const [auth, setAuth] = useState<boolean>(false);
  const [accountName, setAccountName] = useState<string>("");
  const [companyName, setCompanyName] = useState<string>("");
  const [host, setHost] = useState<string>("");
  const [role, setRole] = useState<string>("");

  const isAuthenticated = async () => {
    try {
      const accessToken = window.localStorage.getItem("accessToken");

      if (accessToken) {
        const result = await Axios.post(`${url}/rapidcart/admin/userinfo`);
        console.log('authenticated res:', result);

        let name = result.data.data.account;
        let roleStatus = result.data.data.role;
        let coName = result.data.data.companyName;
        let domainName = result.data.data.host;

        if (domainName === "" && roleStatus === "SUPER_ADMIN") {
          setHost("taotaro.app");
        } else {
          setHost(domainName);
        }

        if (roleStatus === "SUPER_ADMIN" && coName === "") {
          setCompanyName("TaoTaro");
        } else {
          setCompanyName(coName);
        }

        setAccountName(name);
        setAuth(true);
        setRole(roleStatus);

        if (roleStatus === "SUPER_ADMIN") {
          navigate('/super-admin/applications');
        } else {
          navigate('/operations/get-all-inventory');
        }

      } else {
        setAuth(false);
        navigate('/sign-in');
      }

    } catch (err) {
      console.log('isAuthenticated Error:', err);
    }
  };

  const signIn = async (data: object) => {
    let newState = { ...state, backdropOpen: true };
    try {
      setState(newState);
      const result = await Axios.post(`${url}/rapidcart/admin/login`, data);
      console.log('Sign In Res:', result);

      const resData = result?.data;

      const { companyName, host, role, token } = resData?.data || {};

      if (!token) {
        newState = { ...newState, signErrorMessage: resData?.message || 'System Error', open: true };
        setState(newState)
        return;
      }

      setSession(token);

      if (companyName === "" && role === "SUPER_ADMIN") {
        setCompanyName("TaoTaro");
      } else {
        setCompanyName(companyName);
      }

      if (host === "" && role === "SUPER_ADMIN") {
        setHost("taotaro.app");
      } else {
        setHost(host);
      }

      setRole(role);
      setAuth(true);

      if (result.data.status === "success") {
        if (result.data.data.role === "SUPER_ADMIN") {
          navigate('/super-admin/applications');
        } else {
          navigate('/operations/get-all-inventory');
        }
      }
    } catch (error) {
      newState = { ...newState, signErrorMessage: 'System Error', open: false };
      setState(newState)
      console.log('[Sign IN Error]', error)
    } finally {
      setState({ ...newState, backdropOpen: false });
    }
  };

  const signOut = () => {
    localStorage.removeItem("accessToken");
    setAuth(false);
  };

  const register = async (data: object) => {
    try {
      const result = await Axios.post(`${url}/register`, data);

      console.log('Sign Up Res:', result);

      const { token } = result.data.data;

      if (token) {
        setSession(token);
      }

      setAuth(true);

      if (result.data.status === "success") {
        navigate('/dashboards/analytics');
      }

    } catch (error) {
      console.log(error)
    }
  };

  const createApplication = (data: any) => {
    try {
      const result = Axios.post(`${url}/rapidcart/admin/apply/create`, data);
      console.log('Create Application Success:', result);
    } catch (err) {
      console.log('Create Application Error:', err);
    }
  };

  useEffect(() => {
    isAuthenticated();
  }, []);

  return (
    <AuthContext.Provider
      value={({
        auth,
        setAuth,
        accountName,
        setAccountName,
        companyName,
        setCompanyName,
        host,
        setHost,
        role,
        setRole,
        signIn,
        signOut,
        register,
        createApplication,
      })}
    >
      {children}
      <Snackbar
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        open={state.open}
        onClose={() => setState({ ...state, open: false })}
        message={state.signErrorMessage}
      />
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={state.backdropOpen}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
    </AuthContext.Provider>
  )
};

export { AuthContext, AuthProvider };