// Material Dashboard 2 PRO React TS components
import MDBox from "components/MDBox";
import MDButton from "components/MDButton";

// Declaring props types for DefaultCell
interface Props {
  id?: string | boolean;
  edit: (id: any) => void;
  remove: (id: any) => void;
}

function ActionCell({ id, edit, remove }: Props): JSX.Element {
  return (
    <MDBox display="flex" alignItems="center">
        <MDButton 
          variant="contained" 
          color="info"
          onClick={edit}
        >
          Edit
        </MDButton>

        <MDButton 
          variant="outlined" 
          color="error"
          onClick={remove}
          sx={{ marginLeft: "5px" }}
        >
          Delete
        </MDButton>
    </MDBox>
  );
}

// Declaring default props for ActionCell
ActionCell.defaultProps = {
  id: "",
};

export default ActionCell;